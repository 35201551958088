.columnSpace {
    margin: 10px 0;
}
.bendingMoment {
    border: 4px solid transparent;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: relative;
    top: 15px;
    transform: rotate(45deg);
    margin: 0px auto;

    border-top-color: #000;
    border-left-color: #000;
}
.bendingMoment::before {
    content: "";
    position: absolute;
    top: 6px;
    right: 0px;
    border-left: 18px solid;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    transform: rotate(45deg);    
    border-left-color: #000;
}
.horizontalForce {
    height: 4px;
    width: 100px;
    position: relative;
    top: -10px;
    margin: 0px auto;    
    background-color: #000;
}
.horizontalForce::before {
    content: "";
    position: absolute;
    top: -7px;
    left: 90px;
    border-left: 18px solid;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;    
    border-left-color: #000;
}
.foundation {
    width: 40px;
    height: 80px;
    margin: 15px auto;
    background-color: #bfbfbf;
}
.loadsDescription {
    margin: auto;
    text-align: center;
    display: block;
    position: absolute;
    font-size: 15px;    
}
.momentDescription {
    transform: rotate(-45deg);
    left: 10px;
    top: 10px;    
}
.horizontalDescription {
    text-align: center;
    top: -25px;
    position: relative;    
}