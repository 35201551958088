.value {
    position: absolute;
    left: 120mm;    
    width: max-content;
    display: block;
    top: 0;
}
.value1 {
    position: absolute;
    left: 140mm;    
    width: max-content
}
.wrapper p {
    position: relative;
}