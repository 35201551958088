.wrapper{
    min-height: 100%;    
    position: relative;
}
.description {
    z-index: 2;
    width: 100%;
}
.item {
    display: block;
    font-size: 3.5mm;
    color: #000;
}
.spot {
    position: absolute;
    bottom: 2px;
    right: 5px;
    color: #000;
    font-size: 3.5mm;
    font-weight: 500;    
}
.layer {
    position: relative;
    border-bottom: 1px solid #333;    
}
.hiddenLayer {
    display: none;
}

.listWrapperVisible {
    width: auto;
    visibility: visible;
    position: absolute;
    right: 30mm;
    top: 50%;
    transform: translateY(-50%);  
}