.noResults {
    margin-top: 40px;
    text-align: center;
}
.buttonWrapper{
    display: flex;
    flex-wrap: wrap;
}
.maxWidth30 {
    max-width: 30px;
}
.leftSide p {
    margin: 5px 0 0 5px;
}
.margin {
    margin: 0 -5px;
}
.rowMargin {
    margin: 5px -15px;
}
@media (min-width: 576px){
    .leftSide{
        padding-left: 0;
    }
}
.seriesWrapper {
    padding: 10px;
    border-radius: 5px;
}
.header {
    display: flex;
    border-color: inherit;
    align-items: center;
}
.headerItem {
    width: 100%;
    border: 1px solid;
    text-align: center;
    border-left: none;
    border-color: inherit;
}
.headerItem:first-child{
    border-left: 1px solid;
    border-color: inherit;
}

.headerItem p {
    margin: 0;
}
.table {
    margin: 0
}
.table>:not(caption)>*>*{
    padding: 5px 0 !important;
    border-color: #ddd !important;
}
.body {
    border-color: inherit;
}
.inputText{
    padding: 0 !important;
}
.inputText * {
    height: 100%;
    width: 100%;
}
.bodyRow {
    display: flex;
    border-color: inherit;
    cursor: move;
}
.bodyRowItem {
    width: 100%;
    border: 1px solid;
    text-align: center;
    padding: 3px 5px;
    border-color: inherit;
    border-top: none;
    border-left: none;
}
.bodyRowItem:first-child{
    border-left: 1px solid;
    border-color: inherit;
    padding: 0 !important;
}
.bodyRowItem input {
    padding: 0;
}
.removeItem{
    cursor: pointer;
}
.flexCheckbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    margin-top: 5px;
}
.flexCheckbox p {
    margin: 0
}
.checkbox {
    margin-top: 0 !important;
    margin-right: 10px !important;
}
.flex {
    display: flex
}