.modal {
    width: 1050px;
    max-width: 1050px;
}
.modalOverflow{
    overflow: auto !important;
}
.row {
    margin: 30px 0;
}
.row:first-child {
    margin-top: 0;
}
.table td {
    padding: 0;
    height: 40px;
}
.table th {
    padding: 5px 10px;
}
.actionsWrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;    
}
.actions {
    margin: 20px 0;    
}
.account {
    font-size: 15px;
    text-transform: none;
}
.cost {
    font-size: 22px;
    margin: 15px 0;
    text-transform: none;
}
.centerButton {
    text-align: center;
}

.deleteButton{
    margin: 5px 2px;
}
.checkbox {
    margin: 0 !important;
}