.wrapper {
    height: 800px;
    position: relative;   
    border: 1px solid #aaa;
    border-radius: 10px; 
}
.columnSpace {
    margin: 10px 0; 
}
.zwg {
    border-bottom: 2px dashed blue;
    height: 2px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;     
}
.soilsProps {
    text-align: center;
}
.soilsProps svg {
    margin: 25px 0;
    color: #cc1525;
    font-size: 60px;    
}
.p {
    font-size: 15px;    
}
.zwgSpot {
    position: absolute;
    left: 10px;
    top:-15px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid blue;
}
.zwgLevel {
    position: absolute;
    top: -20px;
    left: 35px;
    color: blue;
    font-size: 14px;
    font-weight: 500;    
}
.groundLevel {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}
.spot {
    position: relative;
    font-size: 14px;
    font-weight: 500;    
    color: inherit !important;
    text-align: right;
    width: 100%;
}
.pile {
    position: absolute;
    left: -5px;
    width: 10px;
    background: #888;

}
.pile:before {
    display: block;
    content: "";
    height: 100%;
    width: 8px;
    background: linear-gradient(-45deg, #888 40%, transparent 25%), linear-gradient(-135deg, #888 40%, transparent 25%);
    background-size: 20px 8px;
    background-position: -7px;
    position: absolute;
    left: -7px;
    top: 0;
}
.pile:after {
    display: block;
    content: "";
    height: 100%;
    width: 8px;
    background: linear-gradient(135deg, #888 40%, transparent 25%), linear-gradient(45deg, #888 40%, transparent 25%);
    background-size: 20px 8px;
    background-position: -5px;
    position: absolute;
    right: -7px;
    top: 0;
}
.microHead {
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    flex-direction: column;
}
.topHead {
    height: 20px;
    width: 14px;
    background: #666;
    border: 1px solid #666;
    border-radius: 5px 5px 0 0;
}
.bottomHead {
    height: 6px;
    width: 34px;
    background: #333;
}
.steelRod {
    width: 6px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: repeating-linear-gradient(-45deg,#222, #222 2px, transparent 0, transparent 4px);
}
.rod {
    width: 2px;
    background: #222;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    height: 100%;
}
.pileWrapper {
    position: absolute;
    z-index: 2;
    display: flex;
    transform-origin: left;
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 10px;
}
.circle {
    border-radius: 50%;
}
.pileSpot {
    color: #000;
}
.spotProfile {
    position: absolute;
    bottom: 2px;
    left: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 500;    
}
.pileHeadSpot {
    position: absolute;
    top: 5px;
    right: 40px;
    color: inherit !important;
    font-size: 14px;
    font-weight: 500; 
    width: max-content;   
}

.axisDescription {
    color: #000;
    font-size: 14px;
    transform: rotate(-135deg);
    position: relative;
    top: -10px;
    left: 15px;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 10px;
    background-color: #000;
}
.value {
    color: #000;
    font-size: 14px;
    position: relative;
    top: 10px;
    left: -5px;
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
    top: 0px;
    z-index: 1;
} 
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.freeSpace {
    background: #dedede;
    border-bottom: 1px solid #333;
    border-radius: 10px 10px 0 0;
}
.layersWrapper > div:first-child {
    border-radius: 10px 10px 0 0;
}
.layersWrapper > div:last-child {
    border-radius: 0 0 10px 10px;
}