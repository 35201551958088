.ul {
    padding-left: 0;
    margin-left: 0;
    margin-top: 10px;
    list-style: none;
}
.ul li {
    padding-left: 0;
    margin-left: 0;
    color: red
}