.wrapper {
    cursor: default;
    padding: 15px !important;
    margin: 5px 0;
}
.h2 {
    font-size: 28px;
    text-align: left;
    font-weight: 700;
    
}
.currency{
    text-transform: lowercase;
    font-size: 20px;
}
.h4 {
    font-size: 15px;
    text-align: left;
    margin-bottom: 2px;
}
.icon svg {
    font-size: 24px;
}
.row{
    margin-left: 0;
    margin-right: 0;
}
.col {
    padding: 0;
}
.flexRight{
    display: flex;
    justify-content: flex-end;
}
.optionsWrapper {
    display: flex;
    flex-wrap: wrap;    
}
.optionsWrapper p {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    margin-right: 10px;
}
.optionsWrapper p:hover {
    cursor: pointer;
    text-decoration: underline;
}
.optionsWrapper p:last-child {
    font-size: 14px;
    font-weight: 700;
    margin-right: 0;
}