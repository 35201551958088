.thead th {
    padding: 10px;
}
.tbody td {
    padding: 0
}
.actionsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;    
}
.actions {
    margin: 20px 0;    
}
.tdLeft {
    text-align: left !important;
}
.tdLeft input {
    text-align: left !important;
}