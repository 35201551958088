.ul {
    list-style: decimal;
    padding: 5px 0 5px 5px;
    font-size: 15px;
    margin: 0;
}
.ul li {
    list-style: none;
}
.projectWrapper {
    display: flex;
    justify-content: space-between;    
}
.actionsWrapper button {
    margin: 0 5px;    
}
.actionsWrapper button:last-child {
    margin-right: 0;    
}
.load {
    background: transparent;
    border: none;
    color: inherit;    
}
.delete {
    background: transparent;
    border: none;
    color: inherit;    
}
.paginationWrapper {
    margin: 20px 0 0 0 !important
}
.paginationWrapper ul {
    margin: 0 !important;
    padding: 0;
}
.paginationWrapper ul li a {
    font-size: 15px !important;
    width: 35px !important;
    height: 35px !important;
    line-height: 32px !important;
}
.center {
    text-align: center;
    display: block !important;
}
.row {
    margin: 20px 0;
}
.row:first-child {
    margin-top: 0
}
.row:last-child {
    margin-bottom: 0
}
.table {
    width: 100%;
    cursor: default;
}
.tbody{

}
.tbody:before {
    content:"@";
    display:block;
    line-height:10px;
    text-indent:-99999px;
}