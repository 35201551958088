.row {
    margin: 35px 0;   
}
.row:first-child{
    margin-top: 15px;
}
.loadingWrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.75;
    z-index: 9999;
}
.colLeft {
    padding-left: 0
}
.colRight {
    padding-right: 0;
}
.col {
    padding-left: 0;
    padding-right: 0;
}
@media screen and (max-width: 575px){
    .colLeft {
        padding-right: 0
    }
    .colRight {
        padding-left: 0;
    }
}