.modal {
    width: 800px;
    max-width: 800px;
}
.modalOverflow{
    overflow: auto !important;
}
.row {
    margin: 20px 0;
}
.row:first-child{
    margin-top: 0;
}
.textarea{
    min-height: 200px;
}