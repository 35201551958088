.titleFlex {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 5px;
}
.titleFlex svg {
    margin-top: 5px;
    margin-right: 3px;
}