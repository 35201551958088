.foundation {
    height: 100%;
    background-color: #bfbfbf;
    display: table;    
}
.foundationWrapper {
    max-height: 420px;
    display: flex;
    overflow: auto;    
}
.pile {
    margin: 25px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #666;
    background-color: #999;
    position: relative;    
}
.rectangularPile {
    border-radius: 0;
}
.horizontalAxis {
    height: 1px;
    border-bottom: 1px dashed #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    right: -10px;
    margin: auto;    
}
.verticalAxis {
    width: 1px;
    border-right: 1px dashed #000;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;    
}
.foundationRow {
    display: flex;
}
.loadDirection {
    margin-bottom: 10px;
    text-align: center;    
}
.loadDirection p {
    margin-bottom: 5px;
}
.loadDirection svg {
    font-size: 40px;
}
.pileNumber {
    color: #000;
    position: absolute;
    top: -20px;
    left: 30px;    
}
.foundationAlert {
    text-align: center;
    width: 100%;    
}
.foundationAlert svg {
    margin: 25px 0;
    color: #cc1525;
    font-size: 60px;    
}
.p {
    font-size: 15px;
    margin: 3px 0;    
}
.horizontalDimension {
    width: 100%;
    text-align: center;    
}
.verticalDimension {
    display: flex;
    text-align: center;
}
.verticalDimension p {
    writing-mode: tb-rl;
}
.schemaWrapper {
    display: flex;
    justify-content: center;
}