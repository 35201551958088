.colLeft {
    padding-left: 0
}
.colRight {
    padding-right: 0;
}
.row {
    margin: 35px 0;   
}
.row:first-child{
    margin-top: 15px;
}
.col {
    padding-left: 0;
    padding-right: 0;
}