
.navWrapper {
    display: flex;
    flex-wrap: wrap;
}
.navWrapper a {
    display: flex;
}

.actualResults {
    width: 14px;
    height: 14px;
    background: chartreuse;
    display: block;
    border-radius: 50%;
    margin-left: 8px;
}
.wrongResults {
    background: red;
}
.printButton {
    margin: 0  0 0 5px;
}