.wrapper {
    position: relative;
    width: 100%;
}
.titleWrapper {
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    position: relative;
    width: max-content;
}
.checkboxWrapper {
    margin-right: 15px;
    margin-top: 2px;
    display: flex;
}
.label {
    width: 20px;
    height: 20px;

    margin: 0 !important;
}
.label1{
    background-color: transparent !important;
    margin-left: 10px;
    cursor: pointer;

    position: absolute;
    left: 0;
    top: 1px;
    padding-left: 25px;
}
.checkboxWrapper input:checked,
.checkboxWrapper input:not(:checked) {
    position: absolute;
    left: -9999px;
}
.checkboxWrapper input:checked + .label,
.checkboxWrapper input:not(:checked) + .label
{
    position: relative;
    cursor: pointer;
    display: inline-block;
    border-radius: 100%;
}
.checkboxWrapper input:checked + .label:before,
.checkboxWrapper input:not(:checked) + .label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}
.checkboxWrapper input:checked + .label:after,
.checkboxWrapper input:not(:checked) + .label:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.checkboxWrapper input:not(:checked) + .label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.checkboxWrapper input:checked + .label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
