body {
	margin: 0;
	font-size: 15px;
}
.modal-backdrop {
	z-index: 10 !important;
}
button.close {
	border: none !important;
	padding: 0 !important;
	background: transparent;
}
button.close span {
	line-height: 1 !important;
	display: block;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; 
}
a {
	text-decoration: initial !important;
}