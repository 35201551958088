.wrapper {
    height: 165mm;
    position: relative;    
    width: 100%;
    font-size: 3mm !important; 
    border-radius: 10px;
} 
.chartWrapper {
    border: 1px solid #bbb;
    padding: 0 5mm 5mm 5mm;
    margin-bottom: 5mm;
    width: 100%;
    overflow: hidden;
} 
.wrapper p {
    font-size: 3mm !important;
    line-height: 3.5mm;
    margin: 0;
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
}
.pileWrapper {
    position: absolute;
    padding-left: 8mm;
    padding-top: 0;
    z-index: 1;
    display: flex;
    width: calc(100% - 8mm);
    top: 0;
    height: 100%;
}
.results {
    width: 100%;
    height: 100%;
    position: relative;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 2mm;
    height: 2mm;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.verticalCaret {
    position: absolute;
    right: -4px;
    bottom: 1px;
    display: block;
    width: 2mm;
    height: 2mm;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(225deg);
}
.verticalAxis {
    width: 1px;
    height: 100%;
    background: #000;
    position: relative;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 4mm;
    background-color: #000;
}
.axisCoordVert {
    position: absolute;
    display: block;
    left: -5px;
    width: 4mm;
    height: 1px;
    background-color: #000;
}
.value {
    color: #000;
    position: relative;
    top: 4mm;
    left: -3mm;
}
.valueVert {
    color: #000;
    position: relative;
    top: -2mm;
    left: -4mm;
    margin: 0;
}
.axisDescription { 
    color: #000;
    transform: rotate(-135deg);
    position: relative;
    top: -4mm;
    left: 6mm;
}
.axisVerticalDescription {
    color: #000;
    transform: rotate(-225deg);
    position: relative;
    top: 2mm;
    left: 0;
}
.title {
    margin: 0;
    padding: 2mm 0;
}
.col {
    padding: 0;
}
.row {
    margin: 0;
}
.chartWrapperResult {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.chartWrapperResult svg {
    width: 100%;
    height: 100%;
}
.shaftChart {
    fill:none;
    stroke:black;
    stroke-width: 1;
    stroke-dasharray: 12;
}
.baseChart {
    fill:none;
    stroke:black;
    stroke-width: 1;
    stroke-dasharray: 4;
}
.totalChart {
    fill:none;
    stroke:black;
    stroke-width: 2;
}
svg .text {
    font-size: 12px;
}
svg .textLine {
    font-size: 14px;
}
.pile {
    border: 1px solid #666;
    position: absolute;
    top: 12mm;
    right: 0px;
    width: 16mm;
    height: 22mm;
    background: #e6e6e6;
    border-radius: 3px;
    padding: 2mm 3mm;
}
.pile p {
    text-align: center;
    margin: 0;
}
.pileH {
    width: 20mm;
    padding: 2mm;
}
.pileShape {
    width: 5mm;
    height: 5mm;
    border: 1px solid #666;
    border-radius: 3px;
    background: #aaa;
    margin-bottom: 1mm;
}
.pileShapePref {
    width: 9mm;
    height: 9mm;
    border: 0.1mm solid #666;
    border-radius: 3px;
    background: #aaa;
    position: relative;
    left: 0.2mm;
}
.openEnded {
    width: 10mm;
    height: 10mm;
    border-radius: 50%;
}
.openEndedInner{
    width: 7.8mm;
    height: 7.8mm;
    border-radius: 50%;
    background: #e6e6e6;
    left: 1mm;
    position: relative;
    top: 1mm;
}
.hTypeFlange {
    height: 2mm;
    background: #e6e6e6;
    border: 0.1mm solid #666;
    position: relative;
}
.hTypeHeight {
    position: relative;
    width: 1.5mm;
    height: 6.5mm;
    border: 0.1mm solid #666;
    border-bottom: none;
    border-top: none;
}
.hWrapperView {
    width: 100%;
    border-radius: 3px;
    background: transparent;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1mm;
}
.hWrapperView2H {
    display: flex;
    justify-content: center;
    align-items: center;
}
.hWrapperView2H .hWrapperView {
    position: relative;
    width: auto;
}
.hWrapperView .hTypeHeight {
    top: 0;
    left: 0;
    background: #888;
}
.hWrapperView .hTypeFlange {
    top: 0;
    left: 0;
    width: 10mm;
    background: #888;
}
.hWrapperView2H .hWrapperView .hTypeFlange {
    width: 7mm;
}
.hWrapperView2H .hWrapperView:first-child .hTypeFlange {
    border-right: none;
}
.hWrapperView2H .hWrapperView:last-child .hTypeFlange {
    border-left: none;
}
.circle {
    width: 10mm;
    height: 10mm;
    border-radius: 50%;
}
.currentHorizontalValue {
    position: absolute;
}
.currentVerticalValue {
    top:0;
    position: absolute;
}
.currentSettleValue {
    color: black;
    margin: 0 0 0 1mm !important;
}
.line {
    border-bottom: 1px solid #000;
    opacity: 0.3;
}
.currentForceValue {
    color: black;
    position: absolute;
    top: 5mm;
    transform: rotate(-90deg);
    left: -2mm;
    margin: 0;
}
.lineVertical {
    border-right: 1px solid #000;
    opacity: 0.3;
    height: 100%;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
}