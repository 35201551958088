.fileNameWrapper{
    text-align: center;
}
.fileNameWrapper h2 {
    text-transform: none !important;
    text-align: center !important;
    margin: 15px 0 !important;
}
.h2{
    width: 100%
}