.dateButton {
    position: relative;
    margin: 5px;
    color: inherit;
    border-radius: 5px;
    border: none;
    font-size: inherit;
    text-transform: none;
    padding: 4px 8px;
    transition: all 0.3s ease;
    outline: none !important;

    cursor: pointer;
}
.flex {
    display: flex;
    align-items: center;
}
.clearInput svg {
    font-size: 16px;
    margin-left: 10px
}
.checkbox {
    margin-right: 0;
}