@import 'https://fonts.googleapis.com/css?family=Abhaya+Libre:100,200,200,400,500,600,700&display=swap&subset=sinhala';

html {
  position: relative;
  font-family: "Abhaya Libre", Sans-serif;

}
body {
  font-family: "Abhaya Libre", Sans-serif  !important;
  background: #fff;
}

#interview-app {
 min-height: 100vh;
 height: 100%;
}
.App {
  text-align: center;
}
.modal-open .modal {
  overflow-x: auto;
  overflow-y: auto;
}
