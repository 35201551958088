.wrongDepth {
    color: #e74c3c;
}
.wrongDepth p {
    color: inherit !important;
}
.warningDepth {
    color: #ffa500;
}
.warningDepth p {
    color: inherit !important;
}