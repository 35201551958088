.button {
    float: right;
    margin-top: 20px;
}
.loading {
    margin: 2px 25px !important;
    width: 20px !important;
    height: 20px !important;
}
.pdfWrapper {
    position: relative;

}