
.col p {
    margin: 5px 0;
}
.row {
    margin: 30px -5px;
}
.phase3 {
    margin: 0 0 20px 0;
    color: red;
}