.wrapper{
    min-height: 100%;    
    position: relative;
}
.description {
    z-index: 2;
    width: 100%;
}
.item {
    display: block;
    font-size: 14px;
    color: #000;
}
.heightSpot {
    display: block;
    font-size: 14px;
    color: #000;
    position: absolute;
    right: 10px;
    bottom: 0px;
}
.spot {
    position: absolute;
    bottom: 2px;
    left: 10px;
    color: #000;
    font-size: 15px;
    font-weight: 500;    
}
.layer {
    position: relative;
    border-bottom: 1px solid #333;    
}
.layer:first-child {
    border-top: none;
    border-radius: 10px 10px 0 0;
}
.layer:last-child {
    border-bottom: none;
    border-radius: 0 0 10px 10px;
}
.hiddenLayer {
    display: none;
}
.listWrapperVisible {
    z-index: 1;
    width: auto;
    visibility: visible;
    position: absolute;
    right: 150px;
    top: 50%;
    transform: translateY(-50%);  
}

@media print {
    .item {
        font-size: 3.5mm
    }
    .heightSpot {
        font-size: 3.5mm;
    }
    .spot {
        font-size: 3.5mm  
    }
}