.input {
    width: 100%;
    padding: 8px 5px;
    border: 1px solid transparent;
    text-align: center;
    border-radius: 0 !important;
}
.input::placeholder {
    font-size: 15px;
}
.input:focus {

    outline: none;    
}
.input::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
}
.input::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
}
.inputInvalid {
    border: none;
}
.wrapper {
    display: flex;
    outline: none;
    align-items: center;
    position: relative;
}
.wrapperInvalid {
    outline: 1px dashed red;
}

.fullWidth {
    width: 100%;
}
.fullWidth input {
    border: none;
}
.inputField {
    width: 100%; 
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 0 !important;
}

.inputField:disabled{
    background: transparent;
    color: inherit;
}
.inputField::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField:focus, .textarea:focus {
    outline: none;
}

.group {
    display: table;
    border-collapse: collapse;
    width:100%;
    outline: none;
    border: none;    
}
.group div {
    display: table-cell;
    vertical-align: middle; 
    border: none !important;
}
.groupIcon {
    padding: 0 8px;
    cursor: pointer;
    text-align: center;    
}
.groupIcon svg {
    font-size: 14px;
}