.row {
    margin-top: 15px;
    margin-bottom: 15px;    
}
.row:first-child {
    margin-top: 5px;    
}
.rowMarginTop {
    margin-top: 15px;
    margin-bottom: 0px;
}
.rowMarginBottom {
    margin-top: 0px;
    margin-bottom: 15px;
}