.title {
    color: inherit;
    width: max-content;
    margin-bottom: 0;
}

.infoIcon {
    margin-left: 10px;
}
.info {
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 300px;
    z-index: 1;
    display: none;
}
.info:hover {
    display: block;
}
.wrapper {
    position: relative;
    height: fit-content;
}
.titleWrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

}
.infoIcon:hover + .info {
    display: block;
}
.checkboxWrapper {
    display: flex;
    justify-content: center;
    margin-right: 15px;
    margin-top: 2px;
  }
  
  .checkboxWrapper input {
    position: absolute;
    width: 18px; 
    height: 18px;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }
  
  .checkboxWrapper label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    width: 18px;
    height: 18px;
    border-radius: 5px;
  }
  
  .checkboxWrapper label:before {
    content:'';
    -webkit-appearance: none;
    border-radius: 5px;
    padding: 8px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    width: 18px;
    height: 18px;
  }
  .afterLabel:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 6px;
    width: 6px;
    height: 10px;
    border: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }