
.row {
    margin-top: 30px;
    margin-bottom: 30px;    
}
.row:first-child {
    margin-top: 0px;
    margin-bottom: 30px;    
}
.modal {
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%)
}
.button {
    margin: 5px;
    float: right;
}
.modalDialog {
    width: 100%;
    max-width: 1400px;
}
.ul {
    list-style: decimal;
    padding: 5px 0 5px 5px;
    font-size: 15px;
    margin: 0;
}
.ul li {
    list-style: none;
}
.projectWrapper {
    display: flex;
    justify-content: space-between;    
}
.projectWrapper .p {
    cursor: pointer;    
}
.thead th {
    padding: 10px;
}
.tbody td {
    padding: 0
}
.actionsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;    
}
.actions {
    margin: 20px 0;    
}
.edit {
    margin: 0 5px 0 0; 
}
.tdLeft {
    text-align: left !important;
    padding-left: 5px !important;
}
.tdLeft input {
    text-align: left !important;
}
.save {
    margin: 0  0 0 5px;
}
.select {
    outline: none;
    width: 100%;
    border-radius: 5px;
    padding: 7px 10px; 
    cursor: pointer;
}
.min50{
    min-width: 50px;
}
.min100{
    min-width: 100px;
}
.min150{
    min-width: 150px;
}
.loadingWrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.75;
    z-index: 9999;
}