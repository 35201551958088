.wrapper {
    height: 130mm;
    position: relative;    
    width: 100%;
    font-size: 3mm !important; 
    border-radius: 10px;
} 
.chartWrapper { 
    border: 1px solid #bbb;
    padding: 0 5mm 5mm 5mm;
    margin-bottom: 5mm;
    width: 100%;
    overflow: hidden;
} 
.wrapper p {
    font-size: 3mm !important;
}

.sheetPileLength {
    height: 100%
}
.sheetPileLength p {
    writing-mode: vertical-lr;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    color: #000;
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
}
.pileWrapperCapacityView {
    position: absolute;
    padding-left: 6mm;
    z-index: 1;
    display: flex;
    width: calc(100% - 5mm);
    top: 0;
    height: calc(100% - 5mm);
}
.results {
    width: 100%;
    height: 100%;
    position: relative;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.verticalCaret {
    position: absolute;
    right: -1.5mm;
    bottom: 0;
    display: block;
    width: 3mm;
    height: 3mm;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(225deg);
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 2mm;
    min-width: 15mm;
}
.pileSpot {
    color: #000;
    text-align: center;
    display: block;
}
.spanBlock {
    display: block;
}
.capacityPoint {
    position: absolute;
    margin-top: -2mm;
    margin-left: -1.5mm;
    width: 4mm;
    height: 4mm;
}
.capacityPoint svg {
    position: relative;
    top: -1px;
}
.verticalAxis {
    width: 1px;
    height: calc(100% + 2mm);
    background: #000;
    position: relative;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 4mm;
    background-color: #000;
}
.value {
    color: #000;
    position: relative;
    top: 9px;
    left: -12px;
}
.axisDescription { 
    color: #000;
    transform: rotate(-135deg);
    position: relative;
    top: -5mm;
    left: 5mm;
}
.axisVerticalDescription {
    color: #000;
    transform: rotate(-225deg);
    position: relative;
    top: 1mm;
    left: 0;
}
.title {
    margin: 0;
    padding: 10px 0;
}
.svgLine {
    fill:none;
    stroke:#444;
    stroke-width: 1;
    stroke-dasharray: 0;
}
.svgWrapper{
    position: absolute;
    top: 0;
    left: 0;
    height: 130mm;
    width: 100%;
}
svg .text {
    font-size: 3mm
}
svg .textLine {
    font-size: 3mm;
}
.pileCapacityView {
    border: 1px solid #666;
    position: relative;
    width: 6mm;
    height: 100%;
    background: #888;
    border-radius: 3px;
    flex-shrink: 0;
}
.pileShapeCapacityView {
    width: 5mm;
    height: 5mm;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 1px;
    left: 1px;
    background: #aaa;
}

.openEndedCapacityView {
    width: 4mm;
    height: 4mm;
    border-radius: 50%;
}
.openEndedInnerCapacityView {
    width: 3.5mm;
    height: 3.5mm;
    border-radius: 50%;
    background: #e6e6e6;
    left: 3px;
    position: relative;
    top: 3px;
    border: 1px solid #666;
}
.hWrapper {
    width: 5mm;
    height: 8mm;
    border: 0.1mm solid #666;
    position: absolute;
    border-radius: 3px;
    top: 0.25mm;
    left: 0.25mm;
    background: #aaa;
    padding: 0.4mm;
}
.hTypeFlange {
    width: 4mm;
    height: 1mm;
    background: #e6e6e6;
    border: 0.1mm solid #666;
    position: relative;
    left: 0;
    top: 0
}
.hTypeHeight {
    position: relative;
    top: 0;
    left: 1.5mm;
    width: 1mm;
    height: 4.7mm;
    background: #e6e6e6;
    border: 0.1mm solid #666;
    border-bottom: none;
    border-top: none;
}

.wrapper2H{
    display: flex;
}
.wrapper2H .hWrapper {
    width: 10.1mm;
    height: 9mm;
    border: 0.1mm solid #666;
    position: absolute;
    border-radius: 3px;
    top: 2mm;
    left: -2mm;
    background: #aaa;
    display: flex;
    padding: 1mm;
}
.wrapper2H .hWrapper div:first-child .hTypeFlange {
    border-right: none;
}
.wrapper2H .hWrapper div:last-child .hTypeFlange {
    border-left: none;
}
.circleCapacityView {
    width: 5mm;
    height: 5mm;
    border-radius: 50%;
}
.currentHorizontalValue {
    position: absolute;
}
.currentVerticalValue {
    top:0;
    position: absolute;
}
.validDepth {
    color: #000;
}
.wrongDepth {
    color: #e74c3c;
}
.line {
    border-bottom: 1px solid #000;
    opacity: 0.3;
}
.lineVertical {
    border-right: 1px solid #000;
    opacity: 0.3;
    height: 100%;
}
.capacityDescription p {
    font-size: 4mm !important;
    position: relative;
}
.spanValue {
    position: absolute;
    left: 120mm; 
}