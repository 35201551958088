@media (max-width: 992px) {
    .hideMd{
        display: none;
    }
}
.rowWrapper {
    margin: 0;
}
.colWrapper {
    padding: 0;
}