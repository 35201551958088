.validDepth td:last-child {
    color: #01b169;
}
.tr td {
    padding: 5px 0;
} 
.tr td:last-child {
    position: relative;
    padding: 5px 8px;
} 
.wrongDepth {
    color: #e74c3c;
}
.currentZ td{
    background-color: #f3f3f3
}