.tableResponsive {
    position: relative;
    width: 100%;
}
.tableResponsiveWrapper {
    padding: 15px;
    border-radius: 5px;    
    display: block;
    width: 100%; 
    overflow-x: auto;
}
@media (max-width: 576px) {  
    .tableResponsiveWrapper {
        padding: 10px;            
    }
}
.tableResponsive table {
    color: inherit;
    margin: 0;    
}
.tableResponsive table input {
    color: inherit;
    font-size: inherit !important;
    font-weight: inherit !important;
    font-style: inherit !important;
    text-decoration: inherit !important;    
}
.tableResponsive table thead th {
    border: 1px solid;
    border-color: inherit;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    max-width: 300px;
    background-color: #dedede;
    color: #000;    
}
.tableResponsive table tbody tr td {
    border: 1px solid;
    border-color: inherit;
    text-align: center;
    vertical-align: middle;
}
.tableResponsive p {
    margin: 0;
}
.exportData {
    position: relative;
    top: 5px;
    margin: 10px 15px 10px 0;
    float: left;
}
.exportData svg {
    font-size: 18px;
    cursor: pointer;    
}
.exportData + div a {
    padding: 3px 8px;
    display: block;
    color: inherit;
    text-decoration: none !important;
}
.list {
    position: absolute;
    top: 40px;
    left: 0;
    border-radius: 0;    
}
.hideList {
    display: none;
}

.headerWrapper {
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: space-between;
}

