.default {
    position: relative;
    margin: 5px;
    color: inherit;
    border-radius: 5px;
    border: none;
    font-size: inherit;
    text-transform: none;
    padding:  4px 8px; 
    transition: all 0.3s ease;
    outline: none !important;
}
.wrapperLoading{
    width: auto !important;
    height: auto !important;
}
.icon {
    position: relative; 
    top: 1px;
    margin-right: 6px;    
}
.centerIcon {
    position: relative; 
    top: 0;
    margin: 0 5px;    
}
.info {
    background: #01b169;
    color: #fff;
}
.info:hover {
    background: #01b140;
}
.danger {
    background: #e74c3c;
    color: #fff;
}
.danger:hover {
    background: #e73926;
}
.loading{
    width: 20px !important;
    height: 20px !important;
    margin: 5px 10px 5px 5px !important;
}
.flex {
    display: flex;
    align-items: center;
}