.button {
    color: inherit !important;
    background: inherit;
    border: inherit;
}
.button a {
    display: flex;
    align-items: center;   
    color: inherit !important;
}
.active {
    cursor: default !important;  
    color: inherit !important;
    background: inherit;
}
.active a {
    text-decoration: none; 
    cursor: default;
    color: inherit !important;  
}
.button:first-child {
    margin-left: 0;    
}
.button:last-child {
    margin-right: 0;    
}
.space{
    margin: 0 5px;
}