.firstPage {
    padding: 0;
    text-align: center;  
    color: #000;
    position: relative;
}
.h1 {
    font-weight: 700; 
    margin-top: 0;  
    color: #000;
    width: 100% !important;
    text-align: center;
    font-size: 6mm;
}
.h2 {
    width: 100% !important;
    text-align: center;
    font-weight: normal;
    border: none !important;
    font-size: 5mm;
}
.titleWrapper{
    padding-top: 40mm;
}
.logo {
    padding-top: 35mm;
    width: 100%;
    max-width: 120mm;
    max-height: 120mm;
}

.bold {
    font-weight: bold;
}
.authors {
    padding-top: 30mm;
}
.table tbody td, .table thead th {
    border: 1px solid #333 !important;
    text-align: center;
    color: #000;
    font-size: 4mm;
    padding: 4mm 0 !important;
}
.mm9 {
    width: 85mm;
}
.mm3 {
    width: 40mm
}