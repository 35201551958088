.wrapper {
    min-height: 650px;
    max-height: 1000px;
    position: relative;   
    border: 1px solid #aaa;
    border-radius: 10px; 
}
.soilsProps {
    text-align: center;
}
.soilsProps svg {
    margin: 25px 0;
    color: #cc1525;
    font-size: 60px;    
}
.p {
    font-size: 15px;    
}
.spot {
    position: relative;
    font-size: 15px;
    font-weight: 500;    
} 
.pileLength {
    height: 100%;
    position: absolute;
    left: 12px;
}
.pileLength p {
    writing-mode: vertical-lr;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    color: #000;
}
.pileWrapper {
    position: absolute;
    z-index: 1;    
    width: 100%;
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 10px;
    min-width: 50px;
    height: 100%;
}
.pileShape {
    width: 26px;
    height: 26px;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 1px;
    left: 1px;
    background: #aaa;
}
.circle {
    border-radius: 50%;
}
.pileSpot {
    color: #000;
    font-size: 14px;
    text-align: right;
    padding-right: 10px;
}
.underline {
    text-decoration: underline;
}
.results {
    width: 80%;
    height: 100%;
    position: relative;
}

.capacityPoint {
    position: absolute;
    font-size: 14px;
    margin-top: -7px;
    margin-left: -7px;
    width: 14px;
    height: 14px;
}
.capacityPoint svg {
    position: relative;
    top: -1px;
}
.validDepth {
    color: #000;
}
.wrongDepth {
    color: #e74c3c;
}
.warningDepth {
    color: #ffa500
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.verticalCaret {
    position: absolute;
    right: -4px;
    bottom: 1px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(225deg);
}
.verticalAxis {
    width: 1px;
    height: 100%;
    background: #000;
    position: relative;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 10px;
    background-color: #000;
}
.value {
    color: #000;
    font-size: 14px;
    position: relative;
    top: 3px;
    right: 7px;
}
.axisDescription { 
    color: #000;
    font-size: 14px;
    transform: rotate(-135deg);
    position: relative;
    top: -24px;
    left: 24px;
}
.axisVerticalDescription {
    color: #000;
    font-size: 14px;
    transform: rotate(-225deg);
    position: relative;
    top: -37px;
    left: 17px;
}
.currentHorizontalValue {
    position: absolute;
    height: 1px;
    background-color: #000;
    opacity: 0.3;
}
.currentVerticalValue {
    top:0;
    position: absolute;
    width: 1px;
    background-color: #000;
    opacity: 0.3;
}
.resultsOverview{
    height: 100%;
}
.sticky {
    position: sticky;
    top: 5px;
}
.sticky p {
    margin: 5px 0;
}
.sticky p:first-child {
    margin: 0 0 5px 0;
}
.sticky b {
    font-size: 15px;
}
.warnings {
    margin-top: 25px;   
}
.warnings h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
}
.warnings ol {
    padding-left: 15px;
}
.warnings ol li p {
    margin: 5px 0;
}
.title {
    margin: 0;
    padding: 10px 0;
}
.buttonMarginLeft { 
    margin: 5px 3px 15px 0;
}
.buttonMarginRight {
    margin: 5px 0 15px 3px;
}
.summary {
    border: 1px solid;
    border-color: inherit;
    padding: 10px;
    margin-top: 25px;
    border-radius: 10px;
}
.summary h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
}
.col {
    padding: 0;
}
.row {
    margin: 0;
}
.steelRod {
    width: 6px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: repeating-linear-gradient(-45deg,#222, #222 2px, transparent 0, transparent 4px);
    height: 100%;
}
.rod {
    width: 2px;
    background: #222;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    height: 100%;
}
.pile {
    position: absolute;
    left: 20px;
    width: 10px;
    background: #888;
    height: 100%;
    transform-origin: top;
}
.pile:before {
    display: block;
    content: "";
    height: 100%;
    width: 8px;
    background: linear-gradient(-45deg, #888 40%, transparent 25%), linear-gradient(-135deg, #888 40%, transparent 25%);
    background-size: 20px 8px;
    background-position: -7px;
    position: absolute;
    left: -7px;
    top: 0;
}
.pile:after {
    display: block;
    content: "";
    height: 100%;
    width: 8px;
    background: linear-gradient(135deg, #888 40%, transparent 25%), linear-gradient(45deg, #888 40%, transparent 25%);
    background-size: 20px 8px;
    background-position: -5px;
    position: absolute;
    right: -7px;
    top: 0;
}
.resultsWrapper {
    width: calc(100% - 20px);
    height: 100%;
    display: flex;
}
.pileSchema {
    width: 20%;
    position: relative;
}
.typicalLength {
    margin: 25px 0;
}
.typicalDescription {
    margin: 15px 0 0 0;
}