.section{
    margin: 0 0 35px 0;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}
.rightAlign {
    width: 100%;
    text-align: right;
    margin-top: 15px;
}
.banWrapper {
    text-align: center;
}
.banWrapper svg{
    color: #cc1525;
    margin: 25px 0;
    font-size: 60px;
}