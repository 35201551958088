.table {
    margin: 0 !important;    
}
.table thead tr th {
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
}
.table tbody tr td {
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
}
.value {
    position: absolute;
    left: 100px;    
}
.underline{
    text-decoration: underline;
}