.loadButton {
    margin: 5px 5px 5px 0;
}
.modal {
    max-width: 750px;
    left: 50%;
    transform: translateX(-50%)
}
.button {
    margin: 15px 0;
    float: right;
}
.modalDialog {
    width: auto !important;
}
.loadingWrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.75;
    z-index: 9999;
}
.checkbox {
    margin: 0 5px;
}
.table th {
    padding: 0.5rem;
}
.table td {
    padding: 5px 0;
}
.sectionTitle {
    text-transform: none;
    font-size: 15px;
}
.row {
    margin: 15px 0;
}
.row:first-child{
    margin-top: 0;
}
.pointer{
    cursor: pointer;
}