.row {
    margin: 35px 0;   
}
.row:first-child{
    margin-top: 15px;
}
.loadingWrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.75;
    z-index: 9999;
}