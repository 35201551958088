.wrapper {
    margin: 0;
}
.inputSpace {
    margin: 10px ;
}
.buttonToRight{
    text-align: right;
}
.row {
    margin: 35px 0;   
}
.row:first-child{
    margin-top: 15px;
}