.columnSpace {
    margin: 10px 0;
}
.bottomMargin {
    margin: 0 0 10px 0;
}
.topMargin{
    margin: 10px 0 0 0;
} 
.fullWidth {
    width: 100%;
}
.flexEnd {
    display: flex;
    align-self: flex-end;
}
.flexEnd div:first-child {
    align-self: flex-end;
}
.flexColumn {
    flex-direction: column;
}
.info p {
    margin: 5px 0;
}
.info {
    max-width: 100%;
}
.underline {
    text-decoration: underline;
}

.thead th {
    padding: 10px;
}
.tbody td {
    padding: 0
}

.actionsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;    
}
.actions {
    margin: 10px -5px;    
}
.img {
    width: 450px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -225px;
}
.infoClass {
    max-width: inherit;
}
.radioWrapper p {
    margin : 0;
}