.wrapper {
    min-height: 800px;
    max-height: 1000px;
    position: relative;
    border: 1px solid #aaa;
    border-radius: 10px;
}
.columnSpace {
    margin: 10px 0;
}
.zwg {
    border-bottom: 2px dashed blue;
    height: 2px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index:2;     
}
.zwgRight {
    border-bottom: 2px dashed blue;
    height: 2px;
    position: absolute;
    z-index:2;    
}
.soilsProps {
    text-align: center;
}
.soilsProps svg {
    margin: 25px 0;
    color: #cc1525;
    font-size: 60px;    
}
.p {
    font-size: 15px;    
}
.zwgSpot {
    position: absolute;
    left: 10px;
    top:-15px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid blue;
}
.zwgLevel {
    position: absolute;
    top: -22px;
    left: 35px;
    color: blue;
    font-size: 14px;
    font-weight: 500;    
}
.zwgRightSpot {
    position: absolute;
    right: 100px;
    top:-15px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid blue;
}
.zwgRightLevel {
    position: absolute;
    top: -23px;
    right: 130px;
    color: blue;
    font-size: 14px;
    font-weight: 500;    
}
.groundLevel {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}
.spot {
    position: relative;
    font-size: 14px;
    font-weight: 500;    
}
.sheetPile {
    position: absolute;
    top: 0;
    width: 5px;
    z-index: 1;
    background: repeating-linear-gradient(-45deg,#222, #222 5px, #444 0, #444 10px);
}
.excavationWrapper {
    position: absolute;
    background: #dedede;
    top: 0;
    left: 0;
    z-index: 2;
    border-bottom: 1px solid #000;
    border-radius: 10px 0 0 0;
}
.excavation {
    width: 100%;
    background:#B9D9EB;
    z-index: 2;
    position: relative;
}
.pile {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 10px;
    background: #888;
    left: 26px;
}
.pile:before {
    display: block;
    content: "";
    width: 100%;
    height: 6px;
    background: linear-gradient(45deg, #888 40%, transparent 25%), linear-gradient(-45deg, #888 40%, transparent 25%);
    background-size: 8px 20px;
    background-position: -50px;
    position: absolute;
    top: -6px;
    left: 0;
}
.pile:after {
    display: block;
    content: "";
    width: 100%;
    height: 6px;
    background: linear-gradient(135deg, #888 40%, transparent 25%), linear-gradient(-135deg, #888 40%, transparent 25%);
    background-size: 8px 20px;
    background-position: -50px;
    position: absolute;
    bottom: -6px;
    left: 0;
}
.microHead {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
}
.topHead {
    width: 20px;
    height: 14px;
    background: #666;
    border: 1px solid #666;
    border-radius: 5px 0 0 5px;
}
.bottomHead {
    width: 6px;
    height: 34px;
    background: #333;
}
.steelRod {
    width: calc(100% + 40px);
    height: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    background: repeating-linear-gradient(-45deg,#333, #333 2px, transparent 0, transparent 4px);
    left: -40px;
}
.rod {
    height: 2px;
    background: #333;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    width: 100%;
}
.pileWrapper {
    position: absolute;
    z-index: 2;
    display: flex;
    transform-origin: right center;
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 10px;
}
.circle {
    border-radius: 50%;
}
.pileSpot {
    color: #000;
}
.slideSvg {
    width: 100%;
    min-height: 650px;
    max-height: 1000px;
    position: absolute;
    height: 100%;
    z-index: 1;
}
.slidePolyline {
    fill: none;
    stroke-width:0.5;
    stroke-linecap: round;
    stroke: #222;
}

.spotProfile {
    position: absolute;
    bottom: 2px;
    left: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 500;    
}
.wallBaseSpot {
    position: absolute;
    bottom: -5px;
    right: 15px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
}
.pileHeadSpot {
    position: absolute;
    bottom: -40px;
    left: -15px;
    color: #000;
    font-size: 14px;
    font-weight: 500;    
}

.axisDescription {
    color: #000;
    font-size: 14px;
    transform: rotate(-135deg);
    position: relative;
    top: -10px;
    left: 15px;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 10px;
    background-color: #000;
}
.value {
    color: #000;
    font-size: 14px;
    position: relative;
    top: 10px;
    left: -5px;
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
    top: 0px;
    z-index: 1;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}