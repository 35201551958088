.ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
    font-size: 15px; 
}

.label {
    text-transform: uppercase;
    margin: 10px 0;
    padding-left: 10px;
}
@media (max-width: 576px) 
{ 
    .label {
        display: none;
    }
    .ul {
        width: 100%;        
    }

}