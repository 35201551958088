.wrapper{
    min-height: 100%;    
    position: relative;
}
.description {
    z-index: 2;
    width: 100%;
}
.item {
    display: block;
    font-size: 13px;
    color: #000;
}
.spot {
    position: absolute;
    bottom: 1px;
    right: 32px;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    z-index: 5;
}
.details {
    border-radius: 10px;
    min-width: 150px;
    padding: 15px;    
    display: none;
    position: absolute;
    bottom: 0;
    right: 25px;
    z-index:1;   
    color: #000;
    background-color: #eee;
}
.details:hover {
    display: block;    
}
.icon {
    border: 1px solid #000;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}
.icon:hover .details {
    display: block;
}
.icon:hover .details .item {
    color: inherit;    
}
.icon svg {
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: #000;    
}
.iconHidden {
    display: none
}
.layer {
    position: relative;
    border-bottom: 1px solid #333;
}
.layer:first-child {
    border-radius: 10px 10px 0 0;
    border-top: none;
}
.layer:first-child .icon {
    top: auto;
    bottom: 5px;
    transform: none;
}
.layer:last-child {
    border-radius: 0 0 10px 10px;
    border-bottom: none;
}
.hiddenLayer {
    display: none;
}
.listWrapperHidden {
    width: auto;
    position: absolute; 
    visibility: hidden; 
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.listWrapperVisible {
    width: auto;
    visibility: visible;
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translateY(-50%);  
}