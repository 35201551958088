.row {
    margin-top: 25px;
    margin-bottom: 25px;    
}
.mainRow {
    margin: 0 -15px;
}
.row button {
    width: 100%
}
.row:first-child {
    margin-top: 5px;
}
.row:last-child{
    margin-bottom: 5px;
}
.button {
    margin-left: 0;
    margin-right: 0;
}
.button div:first-child{
    justify-content: center;
}
.tr td {
    padding: 0;
}
.tdLeft {
    text-align: left !important;
    padding-left: 5px !important;
}
.tr td select {
    width: auto;
}
.label {
    margin: 10px 0;
    color: inherit;
}
.modal {
    max-width: 950px;
}
.flex{
    display: flex;
}
.alignEnd{
    align-self: flex-end;
}
.center {
    text-align: center;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
.loadsModal {
    width: 650px;
    max-width: 650px;
}