.modal {
    max-width: 650px;
    left: 50%;
    transform: translateX(-50%)
}
.flex {
    display: flex;
}
.button {
    margin: 15px 0;
    float: right;
}
.addProjectButton {
    margin: 0;
    float: right;
    align-self: flex-end;
    margin-left: auto;
    order: 2;
}
.modalDialog {
    width: auto !important;
}
.row {
    margin: 20px 0;
}
.row:first-child {
    margin-top: 0
}
.row:last-child {
    margin-bottom: 0
}