.wrapper{
    margin-bottom: 25px;
}
.row {
    margin-top: 25px;
    margin-bottom: 25px;    
}
.wrapper .row {
    margin-top: 5px;
}
.row button {
    width: 100%;
}
.row:first-child {
    margin-top: 5px;
}
.row:last-child{
    margin-bottom: 5px;
}
.button {
    margin-left: 0;
    margin-right: 0;
}
.button div:first-child{
    justify-content: center;
}
.label {
    margin: 10px 0;
    color: inherit;
}
.modal {
    max-width: 820px;
    width: auto;
}
.modalOverflow{
    overflow: auto !important;
}
.flexEnd {
    display: flex;
}
.flexEnd div:first-child {
    align-self: flex-end;
}
.flexColumn {
    flex-direction: column;
    flex-flow: wrap;
}
.dialogModal {
    width: auto;
}