.wrapper {
    height: 140mm;
    position: relative;    
    border: 1px solid #000;
    border-radius: 10px;
    width: 100%;
    font-size: 3mm !important;
}
.chartWrapper {
    border: 1px solid #bbb;
    padding: 0 5mm 5mm 5mm; 
    margin-bottom: 5mm;
    width: 100%;
    overflow: hidden;
}
.wrapper p {
    font-size: 3mm !important; 
}
.zwg {
    border-bottom: 2px dashed blue;
    height: 2px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
}
.zwgRight {
    border-bottom: 2px dashed blue;
    height: 2px;
    position: absolute;
    z-index: 2;    
} 
.soilsProps {
    text-align: center;
}
.zwgSpot {
    position: absolute;
    left: 10px;
    top:-15px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid blue;
}
.zwgLevel {
    position: absolute;
    top: -20px;
    left: 35px;
    color: blue;
    font-weight: 500;    
}
.zwgRightSpot {
    position: absolute;
    right: 10px;
    top:-15px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid blue;
}
.zwgRightLevel {
    position: absolute;
    top: -20px;
    right: 35px;
    color: blue;
    font-weight: 500;    
}
.groundLevel {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}
.spot {
    position: relative;
    font-weight: 500;    
    font-size: 3.5mm !important;
}
.sheetPile {
    position: absolute;
    top: 0;
    width: 5px;
    z-index: 1;
    background: repeating-linear-gradient(-45deg,#222, #222 5px, #444 0, #444 10px);
}
.excavationWrapper {
    position: absolute;
    background: #dedede;
    top: 0;
    left: 0;
    z-index: 2;
    border-bottom: 1px solid #000;
    border-radius: 10px 0 0 0;
}
.excavation {
    width: 100%;
    background:#B9D9EB;
    z-index: 2;
    position: relative;
    border-bottom: 1px solid #000;
}
.pile {
    display: flex;
    position: absolute;
    background-color: #888;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 10px;
    left: 26px;
}
.freeLength {
    position: relative;
}
.freeLength:before {
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background: #888 40%;
    position: absolute;
    top: -2px;
    left: 0;
}
.freeLength:after {
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background: #888;
    position: absolute;
    bottom: -2px;
    left: 0;
}
.bond{
    position: relative;
}
.bond:before {
    display: block;
    content: "";
    width: 100%;
    height: 8px;
    background: #464646;
    position: absolute;
    top: -7px;
    left: 0;
    border-radius: 10px 10px 0 0;
}
.bond:after {
    display: block;
    content: "";
    width: 100%;
    height: 8px;
    background: #464646;
    position: absolute;
    bottom: -7px;
    left: 0;
    border-radius: 0 0 10px 10px;
}
.microHead {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
}
.topHead {
    width: 20px;
    height: 14px;
    background: #666;
    border: 1px solid #666;
    border-radius: 5px 0 0 5px;
}
.bottomHead {
    width: 6px;
    height: 34px;
    background: #333;
}
.steelRod {
    width: calc(100% + 40px);
    height: 6px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    background: repeating-linear-gradient(-45deg,#222, #222 2px, transparent 0, transparent 4px);
    left: -40px;
}
.rod {
    height: 2px;
    background: #222;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    width: 100%;
}
.pileWrapper {
    position: absolute;
    z-index: 2;
    display: flex;
    transform-origin: right center;
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 10px;
}

.pileSpot {
    color: #000;
}
.slideSvg {
    width: 100%;
    min-height: 650px;
    max-height: 1000px;
    position: absolute;
    height: 100%;
    z-index: 1;
}
.slidePolyline {
    fill: none;
    stroke-width:1;
    stroke-linecap: round;
    stroke: #000;
}

.spotProfile {
    position: absolute;
    bottom: 2px;
    left: 10px;
    color: #000;
    font-weight: 500;    
}
.wallBaseSpot {
    position: absolute;
    bottom: -5px;
    right: 15px;
    color: #000;
    font-weight: 500;    
}
.pileHeadSpot {
    position: absolute;
    bottom: -40px;
    left: 0;
    color: #000;
    font-weight: 500;   
}

.axisDescription {
    color: #000;
    transform: rotate(-135deg);
    position: relative;
    top: -10px;
    left: 15px;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 10px;
    background-color: #000;
}
.chartValue {
    color: #000;
    position: relative;
    top: 10px;
    left: -5px;
    margin: 0 !important;
}
.underline{
    text-decoration: underline;
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative; 
    top: 0px;
    z-index: 1;
}
.subtitle{
    margin-bottom: 5px;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.title { 
    width:100%;
    text-align: center;
}
.title h2 {
    font-size: 5mm;
    margin: 6mm 0;
    width: 100%;
    font-weight: 700;
    text-decoration: none;
    border: none;
}
.value {
    position: absolute;
    left: 75mm;
    top: 0;
    display: block;
}
.capacityDescription p {
    font-size: 4mm !important;
    position: relative;
}
.angleDescription{
    position: absolute;
    right: 12px;
    top: 22px;
}
.angleDescription span {
    color: #000;
}