.thead th {
    padding: 10px;
}
.tbody td {
    padding: 5px
}
.valid { 
    color: green
}
.inValid {
    color: red
}
.tbody tr td:last-child:hover .info {
    display: block;
} 
.info {
    border-radius: 10px;
    width: max-content;
    max-width: 500px;
    text-align: left;
    font-size: 14px;
    padding: 10px;
    display: none;
    position: absolute;
    bottom: 0;
    right: 0px;
    z-index: 1;
    color: #000;
    background-color: #eee;
}