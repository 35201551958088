.loadButton {
    margin: 5px 5px 5px 0;
}
.row {
    margin-top: 30px;
    margin-bottom: 30px;    
}
.row:first-child {
    margin-top: 0px;
}
.row:last-child {
    margin-bottom: 0;
}
.modalDialogInfo {
    width: 100%;
    max-width: 600px;
}
.modal {
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%)
}
.button {
    margin: 5px;
    float: right;
}
.modalDialog {
    width: 100%;
    max-width: 1400px;
}
.ul {
    list-style: decimal;
    padding: 5px 0 5px 5px;
    font-size: 15px;
    margin: 0;
}
.ul li {
    list-style: none;
}
.ul li .projectWrapper p {
    margin: 0
}
.ul li .projectWrapper {
    margin: 10px 0;
}
.ul li:last-child .projectWrapper {
    margin: 10px 0 0 0;
}
.ul li:first-child .projectWrapper {
    margin: 0;
}

.projectWrapper {
    display: flex;
    justify-content: space-between;    
}
.thead th {
    padding: 10px;
}
.tbody td {
    padding: 0
}
.actionsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;    
}
.actions {
    margin: 20px 0;    
}
.edit {
    margin: 0 5px 0 0; 
}
.tdLeft {
    text-align: left !important;
    padding-left: 5px !important;
}
.tdLeft input {
    text-align: left !important;
}
.save {
    margin: 0  0 0 5px;
}
.select {
    outline: none;
    width: 100%;
    border-radius: 5px;
    padding: 7px 10px; 
    cursor: pointer;
}
.min50{
    min-width: 50px;
}
.min100{
    min-width: 100px;
}
.min150{
    min-width: 150px;
} 
.center {
    text-align: center;
    margin: 25px 0;
}
.actualResults {
    width: 10px;
    height: 10px;
    background: chartreuse;
    display: block;
    border-radius: 50%;
    margin: 6px 5px;
}
.wrongResults {
    background: red;
}
.flex {
    display: flex;
}
.actionCalc{
    cursor: pointer;
    margin: 0 5px;
    height: fit-content;
}
.actionCalc:last-child{
    margin-right: 0;
}
.h2Title{
    font-size: 15px;
    text-align: left;
    margin: 0;
}
.calculationCheckbox{
    margin-right: 5px;
    height: fit-content;
}
.infoWrapper p {
    margin: 5px 0 10px 0;
    display: block;
}