.row {
    margin-top: 30px;
    margin-bottom: 30px;    
}
.row:first-child {
    margin-top: 5px;    
}
.rowMarginTop {
    margin-top: 30px;
    margin-bottom: 0px;
}
.rowMarginBottom {
    margin-top: 0px;
    margin-bottom: 30px;
}