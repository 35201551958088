.crossSection{
    border: 1px solid #000;
}
.crossSection1{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    position: relative;
}
.lineDashed{
    position: absolute;
    height: calc(100% + 40px);
    background: repeating-linear-gradient(0deg,black 0 15px,#0000 0 20px);
    width: 1px;
    top: -20px;
    left: 0;
}
.lineDashed1{
    position: absolute;
    height: calc(100% + 40px);
    background: repeating-linear-gradient(0deg,black 0 15px,#0000 0 20px);
    width: 1px;
    top: -20px;
    right: 0;
}
.flex{
    display: flex;
    justify-content: space-between; 
}
.column{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.column p {
    margin: 3px 0;
    font-size: 18px;
    font-weight: 700;
}
.sectionContainer {
    display: flex;
    justify-content: center;
}
.crossSectionCircular {
    border: 1px solid #000;
    width: 200px;
    height: 200px;
    border-radius: 50%; 
}
.crossSectionCircular p {
    text-align: center;
    margin: 12px 0;
    font-size: 15px;
}
.centerTitle {
    justify-content: center;
}
.spiral {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.spiralCircular {
    height: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.rebar {
    border-radius: 50%;
    background-color: #333;
}
.rebarCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #333;
    position: absolute;
}

.rebarRow {
    text-align: center;
}
.dimension {
    text-align: center;
    margin-top: 10px;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}
.verticalDimension {
    text-align: center;
    margin-right: 10px;    
}
.verticalDimensionRight {
    text-align: center;
    margin-left: 10px;    
}
.verticalDimension p, .verticalDimensionRight p {
    writing-mode: vertical-lr;
    margin: 0;
    height: 100%;
}
.dimension p {
    text-align: center;
    margin-bottom: 0;
}
.line {
    width: 1px;
    height: 16px;
    transform: translate(0, -50%) rotate(45deg);
    background-color: #000;
}
.lineWrapper{
    display: flex;
    justify-content: space-between;
    height: 0px;
    border-left: 1px solid #000;
}
.verticalDimensionRight .lineWrapper {
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: none;
}
.verticalDimension .lineWrapper, .verticalDimensionRight .lineWrapper{
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    display: block;

    position: relative;
}
.verticalDimension .lineWrapper:first-child {
    border-bottom: none;
}
.verticalDimension .lineWrapper:last-child {
    border-top: none;
}
.verticalDimension .lineWrapper .line:first-child, .verticalDimensionRight .lineWrapper .line:first-child {
    position: absolute;
    top: -8px;
    transform: rotate(45deg);
}
.verticalDimension .lineWrapper .line:last-child, .verticalDimensionRight .lineWrapper .line:last-child {
    position: absolute;
    bottom: -8px;
    transform: rotate(45deg);
}
.verticalDimensionRight .lineWrapper .line {
    left: 22px; 
}
.wrapper {
    display: flex;
    flex-direction: column;
}
.rebarDescription {
    text-align: center;
    margin: 2px 0;
    color: #000 !important;
}
.flexRow{
    display: flex;
}