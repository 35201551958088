.wrapper {
    height: 500px;
    position: relative;    
    border: 1px solid #aaa;
    border-radius: 10px;
}
.soilsProps {
    text-align: center;
}
.soilsProps svg {
    margin: 25px 0;
    color: #cc1525;
    font-size: 60px;    
}
.p {
    font-size: 15px;    
}
.spot {
    position: relative;
    font-size: 15px;
    font-weight: 500;    
}
.pile {
    border: 1px solid #666;
    position: relative;
    width: 30px;
    height: 100%;
    background: #888;
    border-radius: 3px;
    flex-shrink: 0;
}
.pileLength {
    height: 100%
}
.pileLength p {
    writing-mode: vertical-lr;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    color: #000;
}
.pileWrapper {
    position: absolute;
    padding-left: 15px;
    z-index: 1;
    display: flex;
    width: calc(100% - 15px);
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0px 1px 0 9px;
    min-width: 53px;
}
.pileShape {
    width: 26px;
    height: 26px;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 1px;
    left: 1px;
    background: #aaa;
}
.circle {
    border-radius: 50%;
}
.pileSpot {
    color: #000;
    font-size: 14px;
    text-align: center;
}

.results {
    width: 100%;
    height: 100%;
    position: relative;
}

.capacityPoint {
    position: absolute;
    font-size: 14px;
    margin-top: -7px;
    margin-left: -7px;
    width: 14px;
    height: 14px;
}
.capacityPoint svg {
    position: relative;
    top: -1px;
}
.validDepth {
    color: #000;
}
.warningDepth {
    color: #ffa500
}
.wrongDepth {
    color: #e74c3c;
}

.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.verticalCaret {
    position: absolute;
    right: -4px;
    bottom: 1px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(225deg);
}
.verticalAxis {
    width: 1px;
    height: calc(100% + 15px);
    background: #000;
    position: relative;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 10px;
    background-color: #000;
}
.value {
    color: #000;
    font-size: 14px;
    position: relative;
    top: 10px;
    left: -13px;
}
.axisDescription { 
    color: #000;
    font-size: 14px;
    transform: rotate(-135deg);
    position: relative;
    top: -10px;
    left: 40px;
}
.axisVerticalDescription {
    color: #000;
    font-size: 14px;
    transform: rotate(-225deg);
    position: relative;
    top: -32px;
    left: 23px;
}
.currentHorizontalValue {
    position: absolute;
    height: 1px;
    background-color: #000;
    opacity: 0.3;
}
.currentVerticalValue {
    top:0;
    position: absolute;
    width: 1px;
    background-color: #000;
    opacity: 0.3;
}
.resultsOverview{
    height: 100%;
    margin-top: 70px;
}
.sticky {
    position: sticky;
    top: 5px;
}
.sticky p {
    margin: 10px 0;
}
.sticky p:first-child {
    margin: 0 0 5px 0;
}
.sticky b {
    font-size: 15px;
}
.warnings {
    margin-top: 25px;   
}
.warnings h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
}
.warnings ol {
    padding-left: 15px;
}
.warnings ol li p {
    margin: 5px 0;
}
.title {
    margin: 0;
    padding: 10px 0;
}
.buttonMarginLeft { 
    margin: 5px 5px 10px 0 !important;
}
.buttonMarginRight {
    margin: 5px 0 10px 5px !important;
}
.summary {
    border: 1px solid;
    border-color: inherit;
    padding: 10px;
    margin-top: 25px;
    border-radius: 10px;
}
.summary h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
}
.col {
    padding: 0;
}
.row {
    margin: 0;
}