.table {
    background-color: #fff;
    color: #000;
    font-family: Calibri, sans-serif !important;
    padding: 0;
    width: 100%;
}
.headerSpace {
    height: 27mm;
    margin-bottom: 5mm;    
}
.footerSpace {
    margin-top: 5mm;    
}

.horizontalAxis{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;

    padding-left: 2mm;
    padding-right: 1mm;
}
.verticalAxis {
    display: flex; 
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -12px;
    left: 0;
}
.chart {
    border: 1px solid #bbb;
    margin-top: 5mm;
    padding: 5mm !important;
    background-color: #fff;
    border-radius: 5px;
}
.chart p {
    color: #000 !important;
}
.chart h1 {
    color: #000 !important;
}
.flex {
    display: flex;
    align-items: center;    
}
.chartWrapper{
    width: 100%;
    display: flex;
    position: relative;
}
.horizontalItem {
    margin-top: 5px;
    position: relative;
}
.horizontalItem p {
    margin: 0;
    font-size: 4mm;

}
.verticalItem {
    width: 100%;
    padding-right: 5px;
    position: absolute;
    height: 24px;
}
.value p {
    writing-mode: vertical-lr;
    margin: 0;
    transform: rotate(180deg);
    width: 100%;
    text-align: left;
    height: 100%;
    padding: 2mm 0 0 0;
    white-space: nowrap;
    letter-spacing: -1px;
    font-size: 3.5mm;
    line-height: 6mm;
}
.line {
    height: 1px;
    background: #ddd;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}
.verticalAxisValue{
    width: 30px;
    position: relative;
    padding: 0 5px 0 0 !important;
    background-color: #fff;
}
.verticalAxisValue p {
    margin: 0;
    text-align: right;
    font-size: 4mm;
}
.horizontalAxisValue {
    position: relative;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.verLabelName {
    writing-mode: vertical-rl;
    white-space: pre;
}
.verLabelName p {
    transform: rotate(180deg);
    margin: 0;
    font-weight: 700;
    padding: 0 2mm 0 2mm;
    height: 100%;
    white-space: pre;
}

.horLabelName {
    text-align: center;
}
.horLabelName p {
    margin: 4mm 0 0 0;
    font-weight: 700;
}
.title {
    margin: 5mm 0 10mm 0;
}
.title h1 {
    font-size: 5mm;
    text-align: center;
    font-weight: 700;
}
.legend {
    text-align: center;
    margin: 3mm 0;
}
.legendItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.pileLength {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.legendItem p {
    margin: 0;
}
.buttonWrapper{
    display: flex;
    flex-wrap: wrap;
}
.chartValue {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    height: 4mm;

    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 2mm;
}
.chartValue p {
    margin: 0 0 5px 0;
    font-size: 14px;
}
.flexChartColumn {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
}
.flexEnd {
    display: flex;
    align-items: flex-end;
}
.flexBars {
    display: flex;
    align-items: flex-end;
}
.pdfWrapper {
    text-align: right;
}
.content {
    padding: 0;
}
.content ol {
    padding: 0;
    margin: 0;
    width: 100%
}
.content:first-child {
    margin-top: -3mm;    
}
.wrapper {
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: collapse;    
}

@media print {
    @page { 
        page-break-after: always; 
        size: a4;
        margin: 0;
    }
    body {
        overflow: hidden;
    }    
    .wrapper {
        position: relative;
        visibility: visible;
        width: 210mm;
        height: 100%;
        overflow: visible;
    }
    .content {
        margin: 0 15mm 0 25mm;
        position: relative;
        print-color-adjust: exact !important;
    }
}