.col {
    padding: 0;
}
.col p {
    margin: 5px 0;
}
.row {
    margin: 0;
}
.phase3 {
    margin: 0 0 20px 0;
    color: red;
}