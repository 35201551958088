.table {
    margin: 0 !important;
    width: 100%;
}
.table th {
    font-size: 4mm !important;
}
.table td {
    font-size: 4mm !important;
}
.wrapper {
    page-break-inside: avoid;
} 