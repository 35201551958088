.modal {
    width: 950px;
    max-width: 950px;
}
.modalOverflow{
    overflow: auto !important;
}
.row {
    margin: 30px 0;
}
.row:first-child {
    margin-top: 0;
}
.table td {
    padding: 5px;
}
.actionsWrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;    
}
.actions {
    margin: 20px 0;    
}
.account {
    font-size: 15px;
    text-transform: none;
}
.cost {
    font-size: 22px;
    margin: 15px 0;
    text-transform: none;
}
.centerButton {
    text-align: center;
}

.deleteButton{
    margin: 5px 2px;
}
.spaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.noUsersCenter{
    text-align: center;
    font-style: italic;
    display: block !important;  
    width: 100%;
    margin: 20px 0 !important;
}
.noUsers{
    text-align: center;
    font-style: italic;
    position: absolute;
    left: 0;    
    width: 100%;
    margin: 20px 0 !important;
}

.absoluteTr{
    height: 50px;
}
.tdNoBorder{
    border: none !important;
}