@keyframes spWaveAf {
	from { 
        transform: scale(0.5,0.5); 
        opacity: 0; 
    }
	to { 
        transform: scale(1,1); 
        opacity: 1; 
    }
}
@keyframes spWaveBe {
	from { 
        transform: scale(1,1); 
        opacity: 1; 
    }
	to { 
        transform: scale(1.5,1.5); 
        opacity: 0; 
    }
}
.icon {
    margin: 10px;
	border-radius: 50%;
	position: relative;
	opacity: 1;
    width: 50px;
    height:50px;    
}
.icon::before, .icon::after {
    content:'';
    border: 1px solid;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left:0px;
    border-color: inherit;
}
.icon::before {
    transform: scale(1,1);
    opacity: 1;
    animation: spWaveBe 0.6s infinite linear;           
}
.icon::after {
    transform: scale(0,0);
    opacity: 0;
    animation: spWaveAf 0.6s infinite linear;            
}
.center {
    display: flex;
    align-items: center;
    flex-direction: column;    
}
.h5 {
    font-size: 15px;
    margin-top: 10px;
    color: inherit;
    text-transform: initial;
}
.wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}