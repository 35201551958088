.modal {
    
    left: 50%;
    transform: translateX(-50%)
}
.modalDialog{
    width: auto !important;
    max-width: 750px;
}
.center {
    text-align: center;
}
.p {
    margin: 5px 0;
    display: block !important;
}
.row {
    margin: 20px 0;
}
.row:first-child {
    margin-top: 0
}
.row:last-child {
    margin-bottom: 0
}