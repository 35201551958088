.header {
    width: 100%;
    position: fixed;
    top:0;
    left:0;
    height: 22mm;
}
.wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;    
}
.wrapper p {
    margin: 3mm 0 !important;
    font-size: 4mm !important;
}
@media print {
    .wrapper {
        padding: 5mm 0;
        margin: 0 15mm 0 25mm;        
    }
}