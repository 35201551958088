.wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.rightWrapper {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
}
.fileNameWrapper{
    text-align: center;
}
.fileNameWrapper h2 {
    text-transform: none !important;
    text-align: center !important;
    margin: 15px 0 !important;
    width: 100%;
}
.flex {
    display: flex;
    flex-wrap: wrap;
}