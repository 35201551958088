.wrapper {
    min-height: 600px;
    max-height: 600px;
    position: relative;   
    border: 1px solid #aaa;
    border-radius: 10px;  
}
.zwg {
    border-bottom: 2px dashed blue;
    height: 2px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index:1;     
}
.hiddenZwg {
    display: none;
}
.soilsProps {
    text-align: center;
}
.soilsProps svg {
    margin: 25px 0;
    color: #cc1525;
    font-size: 60px;    
}
.p {
    font-size: 15px;    
}
.zwgSpot {
    position: absolute;
    right: 120px;
    top:-16px; 
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid blue;
}
.zwgLevel {
    position: absolute;
    top: -20px;
    right: 150px;
    color: blue;
    font-size: 14px;
    font-weight: 500;
}
.groundLevel {
    position: relative;
    left: 5px;
    z-index: 2;
}
.spot {
    position: relative;
    font-size: 15px;
    font-weight: 500;    
}

.pile {
    border: 1px solid #666;
    position: relative;
    width: 30px;
    height: 100%;
    background: #888;
    border-radius: 3px;
}
.pileWrapper {
    position: absolute;
    left: 125px;
    z-index: 1;
    display: flex;
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 10px;
}
.pileShape {
    width: 26px;
    height: 26px;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 1px;
    left: 1px;
    background: #aaa;
}
.circle {
    border-radius: 50%;
}
.pileSpot {
    color: #000;
    font-size: 14px;
}
.freeSpace {
    background: #dedede;
    border-bottom: 1px solid #333;
}