.crossSection{
    width: 200px;
    height: 200px;
    background-color: #ccc;
    border: 1px solid #000;
    padding: 18px;
    position: relative;
}
.crossSection1{
    width: 225px;
    height: 150px;
    background-color: #ccc;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 18px;
    position: relative;
}
.lineDashed{
    position: absolute;
    height: calc(100% + 40px);
    background: repeating-linear-gradient(0deg,black 0 15px,#0000 0 20px);
    width: 1px;
    top: -20px;
    left: 0;
}
.lineDashed1{
    position: absolute;
    height: calc(100% + 40px);
    background: repeating-linear-gradient(0deg,black 0 15px,#0000 0 20px);
    width: 1px;
    top: -20px;
    right: 0;
}
.flex{
    display: flex;
    justify-content: space-between;
}
.rebarDescription{
    color: #000 !important;
}
.column{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.column p {
    margin: 1px 0;
    font-size: 16px;
    font-weight: 700;
}
.sectionContainer {
    display: flex;
    justify-content: center;
}
.crossSectionCircular {
    width: 200px;
    height: 200px;
    background-color: #ccc;
    border: 1px solid #000;
    padding: 18px;
    border-radius: 50%; 
    position: relative;    
}
.crossSectionCircular p {
    text-align: center;
    margin: 12px 0;
    font-size: 18px;
    font-weight: 700;
}
.centerTitle {
    justify-content: center;
}
.spiral {
    border: 3px solid #000;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.spiralNone {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.spiralCircular {
    border: 3px solid #000;
    height: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.rebar {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #000;
}
.rebar1 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
}
.rebar2 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #000;
}
.rebarCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #000;
    position: absolute;
    left: calc(50% - 6px);
    top: calc(50% - 6px);
}
.rebarCircle:nth-child(1) {
    transform: translateY(-74px);
}
.rebarCircle:nth-child(2) {
    transform: rotate(45deg) translateY(-74px);
}
.rebarCircle:nth-child(3) {
    transform: rotate(90deg) translateY(-74px);
}
.rebarCircle:nth-child(4) {
    transform: rotate(135deg) translateY(-74px);
}
.rebarCircle:nth-child(5) {
    transform: rotate(180deg) translateY(-74px);
}
.rebarCircle:nth-child(6) {
    transform: rotate(225deg) translateY(-74px);
}
.rebarCircle:nth-child(7) {
    transform: rotate(270deg) translateY(-74px);
}
.rebarCircle:nth-child(8) {
    transform: rotate(315deg) translateY(-74px);
}
.rebarRow {
    text-align: center;
}
.dimension {
    text-align: center;
    margin-top: 10px;
    width: 100%;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}
.verticalDimension {
    text-align: center;
    border-left: 1px solid #000;
    margin-right: 10px;    
}
.verticalDimension p {
    writing-mode: vertical-lr;
    margin: 0;
    height: 100%;
}
.dimension p {
    text-align: center;
    margin-bottom: 0;
}
.line {
    width: 1px;
    height: 16px;
    transform: translate(0, -50%) rotate(45deg);
    background-color: #000;
}
.lineWrapper{
    display: flex;
    justify-content: space-between;
    height: 0px;
}
.verticalDimension .lineWrapper {
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    display: block;

    position: relative;
}
.verticalDimension .lineWrapper:first-child{
    border-bottom: none;
}
.verticalDimension .lineWrapper:last-child{
    border-top: none;
}
.verticalDimension .lineWrapper .line:first-child {
    position: absolute;
    top: -8px;
    transform: rotate(45deg);
}
.verticalDimension .lineWrapper .line:last-child {
    position: absolute;
    bottom: -8px;
    transform: rotate(45deg);
}
.wrapper {
    display: flex;
}
.xAxis {
    height: 1px;
    background: #999;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    text-align: right;
}
.yAxis {
    width: 1px;
    background: #999;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    height: 40px;
    text-align: right;
}
.xAxis p {
    top: -20px;
    right: 0px;
    position: relative;
    text-align: right;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    color: #000 !important;
}
.yAxis p {
    top: -5px;
    left: 3px;
    position: relative;
    text-align: right;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    color: #000 !important;
}