@keyframes slideOn {
    0% {
        transform: translateX(0) scale(1);
    }
    50% {
        transform: translateX(15px) scale(1.2);
    }
    100% {
        transform: translateX(30px) scale(1);
    }    
}

@keyframes slideOff {
    0% {
        transform: translateX(30px) scale(1);
    }
    50% {
        transform: translateX(15px) scale(1.2);
    }
    100% {
        transform: translateX(0) scale(1);
    }
}
.label {
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 15px 5px 0;
    width: 56px;
    height: 26px;
    position: relative;
}
.ball {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    align-items: center;
    justify-content: center;
    animation: slideOff 0.3s linear forwards;    
}
.checkbox {
    position: absolute;
    width: 100%; 
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}
.checkbox:checked + .label .ball {
    animation: slideOn 0.3s linear forwards;
}
.title {
    color: inherit;
    width: max-content;
    margin: 0;
}
.switch {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    position: relative;
    align-items: center;
    cursor: pointer;    
    min-height: 30px;
}
.switch span svg {
    position: relative;
    z-index: 1;
}
.titleWrapper {
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.infoIcon {
    margin-left: 10px;
}
.info {
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 300px;
    z-index: 2;
    display: none;
}
.info:hover {
    display: block;
}
.infoIcon:hover + .info {
    display: block;
}
.wrapper {
    position: relative;
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}