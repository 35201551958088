.footer {
    min-height: 20mm;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;    
} 
.wrapper {
    border-top: 1px solid #000;
    text-align: left;
    height: 100%;    
}
.wrapper p {
    margin: 2mm 0 0 0 !important;
    font-size: 4mm;
}
.wrapper p:last-child {
    margin-top: 0mm !important;
}
@media print {
    .wrapper{
        padding: 0;
        margin: 0 15mm 0 25mm;            
    }
}