.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;    
}
.h3 {
    font-size: 15px;
}
.flexAlign {
    display: flex;
    align-items: center;    
}