.layer {
    position: relative;
    border-color: transparent;    
}
.layer:first-child {
    border-top: none;
    border-radius: 10px 10px 0 0;
}
.layer:last-child {
    border-bottom: none;
    border-radius: 0 0 10px 10px;
}
.hiddenLayer {
    display: none;
}