.wrapper {
    margin: 0;
}
.inputSpace {
    display: flex;
    flex-wrap: wrap;
}
.inputSpace div{
    width: auto;
}
.flex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.avatar{
    width: 100%;
    max-height: 500px;
}
.italic{
    font-style: italic;
}
.buttonWidth{
    min-width: 130px;
}
.col:first-child {
    padding-right: 5px;
}
.col:last-child {
    padding-left: 5px;
}