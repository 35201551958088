.button {
    float: right;
    margin-top: 20px;
    padding: 5px 8px;
}
.button div:first-child{
    display: flex;
}
.loading {
    margin: 2px 10px !important;
    width: 20px !important;
    height: 20px !important;
}