.validDepth {

}
.validDepth {

}
.validDepth td:last-child {
    color: #01b169;
}
.validCapacity {
    color: #01b169;
}
.wrongDepth {
    color: #e74c3c;
}
.warningDepth {
    
}
.warningDepth td:last-child {
    color: #ffa500
}
.tr{}
.tr td {
    padding: 5px 0;
} 
.tr td:last-child {
    position: relative;
    padding: 5px 8px;
} 
.tdLeft {
    padding-left: 5px !important;
    text-align: left !important;
}
.info {
    border-radius: 10px;
    width: max-content;
    max-width: 500px;
    text-align: justify;
    font-size: 14px;
    padding: 10px;
    display: none;
    position: absolute;
    bottom: 0;
    right: 40px;
    z-index: 1;
    color: #000;
    background-color: #eee;
}
.tr td:last-child:hover .info {
    display: block;
} 
.tr td:last-child:hover .info ol {
    padding-left: 15px;
    margin-bottom: 0;
} 
.tr:first-child .info, .tr:nth-child(2) .info, .tr:nth-child(3) .info {
    bottom: auto;
    top: 0;
}
.depthColor {
    color: #000
}
.tbody {
    position: relative;
}