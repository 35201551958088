.fullWidth {
    width: 100%;
}
.fullWidth input {
    border: none;
}
.inputField {
    width: 100%; 
    padding: 5px 10px;
}
.inputField::-webkit-inner-spin-button, .textarea::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField::-webkit-outer-spin-button, .textarea::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField:focus {
    outline: none;
}
.group {
    color: inherit;
    border-radius: 7px;
    display: flex;
}
.fullWidth input {
    border-radius: 7px 0 0 7px;
}
.groupIcon {
    padding: 0 8px;
    cursor: pointer;
    text-align: center;    

    align-content: center;
    align-items: center;
    align-self: center;
}
.wrapper {
    position: relative;
    width: 100%;
}