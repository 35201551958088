.wrapper{
    margin-bottom: 25px;
}
.row {
    margin-top: 25px;
    margin-bottom: 25px;    
}
.row1 {
    margin-top: 5px;
    margin-bottom: 5px;    
}
.marginBottom {
    margin-bottom: 20px;
}
.wrapper .row {
    margin-top: 5px;
}
.row button {
    width: 100%;
}
.row:first-child {
    margin-top: 5px;
}
.row:last-child{
    margin-bottom: 5px;
}
.button {
    margin-left: 0;
    margin-right: 0;
}
.button div:first-child{
    justify-content: center;
}
.p {
    display: block !important;
    margin: 10px 0;
    text-align: center;
}
.columnSpace {
    margin: 10px 0;
}

.tr td {
    padding: 0;
    font-size: 14px;
}
.tdLeft {
    text-align: left !important;
    padding-left: 5px !important;
}
.tr td select {
    width: auto;
}
.label {
    margin: 10px 0;
    color: inherit;
}
.modal {
    width: calc(100% - 20px);
    max-width: 1050px;
}
.modalOverflow{
    overflow: auto !important;
}
.flexEnd {
    display: flex;
}
.flexColumn {
    flex-direction: column;
}
.info {
    max-width: 100%;
    width: 100%;
}
.margin {
    margin: 10px 0 20px 0;
}