.wrapper {
    text-align: center;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 5;
}
.wrapper button {
    padding: 7px 20px !important;
    margin-right: 0 !important;
}