
.flexCheckbox {
    margin: 0;
    display: flex;

}
.flexCheckbox p {
    width: 100%;
    cursor: pointer;
}
.checkboxWidth {
    width: auto;
}
.checkbox {
    margin: 0 5px;
}
.table th {
    padding: 0.5rem;
    cursor: default;
}
.table td {
    padding: 5px 0;
}
.noUsers{
    text-align: center;
    font-style: italic;
    position: absolute;
    left: 0;    
    width: 100%;
    margin: 20px 0 !important;
}
.absoluteTr{
    height: 50px;
}
.tdNoBorder{
    border: none !important;
}
.actionsWrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
}
.actions {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
.spaceBetween {
    justify-content: space-between;
}

.tdFlex {
    display: flex;
    justify-content: center;
}
.editButton{
    margin: 5px 2px;
}
.p16{
    font-size: 15px;
}
.flexSpace{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
}
.confirmModal {
    width: 480px;
    max-width: 480px;
    text-align: center;
}
.pagination {
    width: 100%;
}
.min110{
    min-width: 110px;
}