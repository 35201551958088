.wrapper {
    height: 135mm;
    position: relative;    
    border: 1px solid #000;
    width: 100%;
    font-size: 3mm !important; 
}
.chartWrapper {
    border: 1px solid #bbb;
    padding: 0 5mm 5mm 5mm;
    margin-bottom: 5mm;
    width: 100%;
    overflow: hidden;
    page-break-inside: auto;
}
.wrapper p {
    font-size: 3mm !important;
}
.spot {
    position: relative;
    font-weight: 500;  
    font-size: 3.5mm !important;  
}
.pileLength {
    height: 100%;
    position: absolute;
    left: 3mm;
}
.pileLength p {
    writing-mode: vertical-lr;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 2mm;
    right: 0;
    color: #000;
}
.pileWrapper {
    position: absolute;
    z-index: 1;    
    width: 100%;
}
.spotWrapper {
    padding-left: 10px;
    min-width: 50px;
    height: 100%;
}
.pileShape {
    width: 8mm;
    height: 8mm;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 1px;
    left: 1px;
    background: #aaa;
}
.circle {
    border-radius: 50%;
}
.pileSpotTop {
    color: #000;
    text-align: left;
    position: absolute;
    top: 0;
    left: 3mm;
}
.pileSpotBottom {
    color: #000;
    text-align: right;
    position: absolute;
    bottom: 0;
}
.results {
    width: 100%;
    height: 100%;
    position: relative;
}

.capacityPoint {
    position: absolute;
    margin-top: -2mm;
    margin-left: -1.5mm;
    width: 4mm;
    height: 4mm;
}
.capacityPoint svg {
    position: relative;
    top: -1px;
}
.validDepth {
    color: #000;
}
.wrongDepth {
    color: #e74c3c;
}
.warningDepth {
    color: #ffa500
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
}
.caret {
    position: absolute;
    right: 0;
    top: -1.5mm;
    display: block;
    width: 3mm;
    height: 3mm;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.verticalCaret {
    position: absolute;
    right: -1.5mm;
    bottom: 0;
    display: block;
    width: 3mm;
    height: 3mm;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(225deg);
}
.verticalAxis {
    width: 1px;
    height: 100%;
    background: #000;
    position: relative;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 4mm;
    background-color: #000;
}
.value {
    color: #000;
    position: relative;
    top: 3px;
    right: 7px;
}
.axisDescription { 
    color: #000;
    transform: rotate(-135deg);
    margin: 40px 0px 0px 23px !important;
}
.axisVerticalDescription {
    color: #000;
    transform: rotate(-225deg);
    margin: 0 10px 0 0 !important;
}
.currentHorizontalValue {
    position: absolute;
    height: 1px;
    background-color: #000;
    opacity: 0.3;
}
.currentVerticalValue {
    top:0;
    position: absolute;
    width: 1px;
    background-color: #000;
    opacity: 0.3;
}
.resultsOverview{
    height: 100%;
}
.sticky {
    position: sticky;
    top: 5px;
}
.sticky p {
    margin: 5px 0;
}
.sticky p:first-child {
    margin: 0 0 5px 0;
}
.warnings {
    margin-top: 5mm;   
}
.warnings h3 {
    text-align: left;
    font-size: 4mm;
    text-transform: uppercase;
}
.warnings ol {
    padding-left: 15px;
}
.warnings ol li p {
    margin: 5px 0;
}
.title {
    margin: 0;
    padding: 0;
}
.summary {
    border: 1px solid;
    border-color: inherit;
    padding: 10px;
    margin-top: 5mm;
}
.summary h3 {
    text-align: left;
    font-size: 4mm;
    text-transform: uppercase;
}
.steelRod {
    width: 6px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: repeating-linear-gradient(-45deg,#222, #222 2px, transparent 0, transparent 4px);
    height: 100%;
}
.rod {
    width: 2px;
    background: #222;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    height: 100%;
}
.pile {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 10px;
    background: #888;
    height: 100%;
    transform-origin: top;
}
.pile:before {
    display: block;
    content: "";
    height: 100%;
    width: 8px;
    background: #888;
    background-size: 20px 8px;
    background-position: -7px;
    position: absolute;
    left: -7px;
    top: 0;
}
.pile:after {
    display: block;
    content: "";
    height: 100%;
    width: 8px;
    background: #888;
    background-size: 20px 8px;
    background-position: -5px;
    position: absolute;
    right: -7px;
    top: 0;
}
.resultsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
}
.pileSchema {
    width: 45mm;
    position: relative;
}
.title {
    width:100%;
    text-align: center;
}
.title .h2 {
    font-size: 5mm;
    width: 100%;
    font-weight: 700;
    text-decoration: none;
    margin: 5mm 0;
    color: #000;
    text-transform: initial; 
    border-bottom: none;
}
.elevationMicropile{
    margin: 0 !important;
}
.capacityDescription {
    page-break-inside: auto;
}
.capacityDescription p {
    font-size: 4mm !important;
    position: relative;
}