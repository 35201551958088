.modal {
    z-index: 1040;
}
.modalDialog {
    padding-top: 50px;
    padding-bottom: 25px;
    max-width: 850px;
    min-width: 300px;
    color: #333; 
    margin-top: 0; 
    position: absolute;
    left: 0; 
    right: 0;
    margin: auto; 
    width: fit-content;
}
.modalContent {
    border: none;
}
.modalHeader {
    border-bottom: 1px solid #999;
    padding: 5px 15px;
    align-content: center;
    align-items: center;
}
.modalHeader button {
    outline: none !important;
    color: #333;
    font-size: 30px;
    padding: 0 !important;
    margin: 0 !important;    
}
.modalTitle {
    font-weight: 500;
    font-size: 15px; 
}
.modalFooter {
    padding: 0 15px 15px 15px;
    border-top: none;
    display: block;
}
.modalBody {
    padding: 20px 15px 20px 15px;
    color: inherit;
}
.modalBody p{
    display: contents;
}
.buttonsWrapper {
    text-align: right;
    margin-right: 0;    
}
.defaultButton {
    color: #000;
    transition: all 0.5s ease;
    background-color: #f9f9f9;
    border-color: #666;
    margin: 3px;
    box-shadow: none; 
}
.actions {
    display: flex;
    align-items: center;
}
.fullScreen {
    margin: 3px 10px 0 10px;
    cursor: pointer;
}
.fullScreenDialog {
    max-width: -webkit-fill-available !important; 
    width: -webkit-fill-available !important; 
    padding-top: 0 !important;
    padding-bottom: 0 !important; 
    padding: 5px !important;
}