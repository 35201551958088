.value {
    position: absolute;
    left: 90mm;
    display: block;
    top: 0;
}
.wrapper p {
    text-align: left;
    position: relative;
}
.wrapper h2 {
    text-align: left;
    display: initial;
}
.margins {
    margin: 2mm 0;
}