.modal1 {
    width: auto;
    max-width: 900px;
    padding-top: 20px;
}
.flexCheckbox {
    margin: 0;
    display: flex;

}
.flexCheckbox p {
    width: 100%;
    cursor: pointer;
}
.checkboxWidth {
    width: auto;
}
.row2 {
    margin: 15px 0;
}
.radioForm {
    display: flex;
    justify-content: space-between;
}