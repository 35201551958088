.wrapper {
    margin-bottom: 50px;
}
.value {
    margin-left: 15px;
}
.actualResults {
    width: 14px;
    height: 14px;
    background: chartreuse;
    display: block;
    border-radius: 50%;
    margin-left: 8px;
}
.wrongResults {
    background: red;
}
.flex {
    display: flex;
    align-items: center;
}