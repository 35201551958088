.modal {
    width: 650px;
    max-width: 650px;
}
.modalOverflow{
    overflow: auto !important;
}
.row {
    margin: 30px 0;
}
.row:first-child {
    margin-top: 0;
}
.button {
    margin: 0 5px;
}
.button:first-child{
    margin-left: 0;
}
.button:last-child{
    margin-right: 0;
}
.flexButtons {
    display: flex;
    justify-content: space-between;
}
.right {
    text-align: right;
}
.infoField {
    max-width: 400px;
}