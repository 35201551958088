.tr{}
.tr td {
    padding: 5px 0;
} 
.tr td:last-child {
    position: relative;
    padding: 5px 8px;
} 
.tdLeft {
    padding-left: 5px !important;
    text-align: left !important;
}
.tr td:last-child:hover .info {
    display: block;
} 
.tr td:last-child:hover .info ol {
    padding-left: 15px;
    margin-bottom: 0;
} 
.tr:first-child .info, .tr:nth-child(2) .info, .tr:nth-child(3) .info {
    bottom: auto;
    top: 0;
}

.tbody {
    position: relative;
}