.value {
    position: absolute;
    left: 80mm;
    display: block;
    top: 0;
}
.value1 {
    position: absolute;
    left: 100mm;
    display: block;
    top: 0;
}
.value2 {
    position: absolute;
    left: 70mm;
    display: block;
    top: 0;
}
.wrapper p {
    position: relative;
}
.rootLine {
    border-top: 0.1mm solid black;
    position: relative;
    right: 0.5mm;
    top: 0.5mm;
    font-size: 4mm;
}
.radic {
    font-size: 5.5mm;
    line-height: 0;
}