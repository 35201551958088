.columnSpace {
    margin: 10px 0;
}
.p {
    margin: 0;
}
.wrapper {
    margin: 0 0 60px 0;
}
.image {
    width: 100%;
    height: 120px;
    cursor: pointer;
    opacity: 0.55;
    border: 1px solid #000;
}
.image1 {
    width: 100%;
}
.image2 {
    width: 80%;
}
.image3 {
    width: 90%;
}
.selectedImage {
    border: 3px solid #000;
    opacity: 1;
}
.description {
    font-weight: 700;
    margin-top: 5px;
    text-align: center;
}
.center {
    text-align: center;
}