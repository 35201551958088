.colLeft {
    padding-left: 0
}
.colRight {
    padding-right: 0;
}
.col {
    padding-left: 0;
    padding-right: 0;
}
.colXs{

}
@media screen and (max-width: 768px){
    .colLeft {
        margin: 5px 0;
        padding-right: 0
    }
    .colRight {
        margin: 5px 0;
        padding-left: 0;
    }
    .colXs{
        margin: 5px 0;
        padding-left: 0;
        padding-right: 0;
    }
}