.h4 {
    color: inherit;
    text-transform: none;
    font-size: 15px;
}
.h4 span {
    font-weight: 700
}
.modal {
    max-width: 550px; 
    left: 50%;
    transform: translateX(-50%)
}
.modalDialog{
    width: auto !important;
}
.button {
    margin: 15px 0;
    float: right;
}