
.flexCheckbox {
    margin: 0;
    display: flex;

}
.flexCheckbox p {
    width: 100%;
    cursor: pointer;
}
.checkboxWidth {
    width: auto;
}

.table th {
    padding: 0.5rem;
}
.table td {
    padding: 0px;
}
.table1 th {
    padding: 0.5rem;
}
.table1 td {
    padding: 8px;
}
.details {
    padding-left: 25px;
}
.noUsers{
    text-align: center;
    font-style: italic;
    position: absolute;
    left: 0;    
    width: 100%;
    margin: 20px 0 !important;
}
.absoluteTr{
    height: 50px;
}
.actionsWrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;    
}
.actions {
    margin: 20px 0;
    display: flex;
    justify-content: right;
    width: 100%;
}
.spaceBetween {
    justify-content: space-between;
}
.tdNoBorder{
    border: none !important;
}
.tdFlex {
    display: flex;
    justify-content: center;
}
.editButton{
    margin: 5px 2px;
}
.p16{
    font-size: 15px;
}
.flexSpace{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.confirmModal {
    width: 480px;
    max-width: 480px;
    text-align: center;
}
.upper {
    text-transform: uppercase;
    margin-bottom: 0;
}
.detail p {
    width: 100%;
    display: block;
}
.pointer {
    cursor: pointer;
}

.row {
    margin: 15px 0;
}
.row1 {
    margin: 30px 0 10px 0;
}
.row:first-child {
    margin-top: 0;
}
.row:last-child {
    margin-bottom: 0;
}
.radioForm {
    display: flex;
    justify-content: space-between;
}
.modal {
    width: 800px;
    max-width: 800px;
}
.modalOverflow{
    overflow: auto !important;
}
.toRight {
    text-align: right;
}
.flex {
    display: flex;
}