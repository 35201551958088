.icon {
    text-align: center;
}
.icon svg {
    width: 65px !important;
    height: 65px;
    margin: 25px 0;
    color: inherit;
}
.row {
    margin: 70px 0 25px 0;
    text-align: center;
}
.button {
    padding: 5px 15px;
}
.button a {
    color: inherit !important;
    text-decoration: none;
}
.p {
    margin: 20px 0 40px 0;
    padding-bottom: 40px;
    border-bottom: 1px solid;
    border-color: inherit;
}