.table {
    margin: 0 !important;    
}
.underline{
    text-decoration: underline;
}

.value {
    position: absolute;
    left: 20mm;
    display: block;
    top: 0;
}
.wrapper {
    page-break-inside: avoid;
}
.wrapper p {
    position: relative;
}