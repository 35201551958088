.horizontalAxis{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;

    padding-left: 40px;
    padding-right: 15px;
}
.verticalAxis {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -12px;
    left: 0;
}
.chart {
    padding: 20px 15px 20px 15px !important;
}
.flex {
    display: flex;
    align-items: center;    
}
.chartWrapper{
    width: 100%;
    display: flex;
    position: relative;
}
.horizontalItem {
    margin-top: 5px;
    position: relative;
}
.horizontalItem p {
    margin: 0;
    font-size: 14px;

}
.verticalItem {
    width: 100%;
    padding-right: 5px;
    position: absolute;
    height: 24px;
}
.value p {
    writing-mode: vertical-lr;
    margin: 0;
    transform: rotate(180deg);
    width: 100%;
    text-align: left;
    height: 100%;
    padding: 5px 0 0 0;
    white-space: nowrap;
    font-size: 14px;
    color: #000;
}
.line {
    height: 1px;
    background: #ddd;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}
.verticalAxisValue{
    width: 30px;
    position: relative;
    padding: 0 5px 0 0 !important;
}
.verticalAxisValue p {
    margin: 0;
    text-align: right;
    font-size: 14px;
}
.horizontalAxisValue {
    position: relative;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.verLabelName {
    writing-mode: vertical-rl;
}
.verLabelName p {
    transform: rotate(180deg);
    margin: 0;
    font-weight: 700;
    padding: 0 0 0 10px;
}

.horLabelName {
    text-align: center;
}
.horLabelName p {
    margin: 15px 0 0 0;
    font-weight: 700;
}
.title {
    margin: 10px 0 50px 0;
}
.title h1 {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
}
.legend {
    text-align: center;
    margin: 10px 0;
}
.legendItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.pileLength {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.legendItem p {
    margin: 0;
}
.buttonWrapper{
    display: flex;
    flex-wrap: wrap;
}
.chartValue {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    height: 20px;

    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 10px;
}
.chartValue p {
    margin: 0 0 5px 0;
    font-size: 14px;
}
.flexChartColumn {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;
}
.flexEnd {
    display: flex;
    align-items: flex-end;
}
.flexBars {
    display: flex;
    align-items: flex-end;
}
.pdfWrapper {
    text-align: right;
}