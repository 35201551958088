.columnSpace {
    margin: 10px 0;
}
.wrongSoilLayers {
    display: inline-flex;
    align-items: center;
}
.wrongSoilLayers svg {
    color: #cc1525;
    margin-right: 10px;
    font-size: 20px;
    position: relative;
    top: 2px;
}
.p {
    margin: 0;
}