
.flexCheckbox {
    margin: 0;
    display: flex;

}
.flexCheckbox p {
    width: 100%;
    cursor: pointer;
}
.checkboxWidth {
    width: auto;
}

.table th {
    padding: 0.5rem;
    cursor: default;
}
.table td {
    padding: 5px 0;
}
.noUsers{
    text-align: center;
    font-style: italic;
    position: absolute;
    left: 0;    
    width: 100%;
    margin: 20px 0 !important;
}
.absoluteTr{
    height: 50px;
}
.tdNoBorder{
    border: none !important;
}

.spaceBetween {
    justify-content: space-between;
}

.tdFlex {
    display: flex;
    justify-content: center;
}

.p16{
    font-size: 15px;
}
.flexSpace{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
}
.pagination {
    width: 100%;
}
.min110{
    min-width: 110px;
}
.pointer {
    cursor: pointer;
}
.row {
    margin: 15px 0;
}
.row1 {
    margin: 30px 0 10px 0;
}
.row:first-child {
    margin-top: 0;
}
.row:last-child {
    margin-bottom: 0;
}
.modal {
    width: 850px;
    max-width: 850px;
}
.modalOverflow{
    overflow: auto !important;
}
.upper {
    text-transform: uppercase;
    margin-bottom: 0;
}
.detail p {
    width: 100%;
    display: block;
}
.dateButton {
    position: relative;
    margin: 5px;
    color: inherit;
    border-radius: 5px;
    border: none;
    font-size: inherit;
    text-transform: none;
    padding: 4px 8px;
    transition: all 0.3s ease;
    outline: none !important;

    cursor: pointer;
}
.flex {
    display: flex;
    align-items: center;
}
.clearInput svg {
    font-size: 16px;
    margin-left: 10px
}
.checkbox {
    margin-right: 0;
}
.list {
    margin-left: 10px;
}
.radioForm {
    display: flex;
    justify-content: space-between;
}
.modalOverflow{
    overflow: auto !important;
}
.toRight {
    text-align: right;
}
