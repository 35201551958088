.table {
    margin: 0 !important;    
}
.value {
    position: absolute;
    left: 20mm;
    top: 0;
    display: block;
}
.underline{
    text-decoration: underline;
}
.wrapper h2 {
    display: initial;
}
.wrapper p {
    position: relative;
}
.margins {
    margin: 2mm 0;
 }