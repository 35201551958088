.table {
    margin: 0 !important;
}
.value {
    position: absolute;
    left: 20mm;
    top: 0;
    display: block;
}
.underline{
    text-decoration: underline;
}
.table th {
    font-size: 4mm !important;
}
.table td {
    font-size: 4mm !important;
}
.wrapper p {
    position: relative;
}