
.inputField {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.inputField::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField:focus {
    outline: none;
}
.label {
    color: inherit;
}
.labelInput {
    position: relative;
    cursor: pointer;
    color: inherit;
    border-radius: 5px;
    border: none;
    font-size: inherit;
    text-transform: none;
    padding: 4px 8px;
    transition: all 0.3s ease;
    outline: none !important;
    margin: 5px;
    width: max-content;
}
.labelInput svg {
    margin-right: 10px;
}
.group {
    display: table;
    border-collapse: collapse;
    width:100%;
    outline: none;
    border: none;
}
.group div {
    display: table-cell;
    vertical-align: middle; 
}
.groupIcon {
    padding: 0 8px;
    cursor: pointer;
    text-align: center;    
}
.titleWrapper {
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.infoIcon {
    margin-left: 10px;
}
.info {

    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 300px;
    z-index: 1;
    display: none;
}
.info:hover {
    display: block;
}
.wrapper {
    position: relative;
    width: 100%;
}
.infoIcon:hover + .info {
    display: block;
}
.title {
    color: inherit;
    width: max-content;
    margin-bottom: 0;
}