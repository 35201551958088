.section{
    margin: 0 0 50px 0;
}
.sectionTitleWrapper h4{
    font-size: 15px;
    text-align: left;
    text-transform: none;
}
.row {
    margin-top: 30px;
    margin-bottom: 30px;    
}
.row:first-child {
    margin-top: 5px;    
}
.flexPrint {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}