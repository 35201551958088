.wrapper {
    text-align: center;
    margin: 40px 0;    
}
.pagination {
    justify-content: center;
    display: flex;
    list-style: none;
    padding-left: 0;
}
.pagination li {
    margin: 0 5px; 
}
.defaultLink {
    width: 40px; 
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 37px;
    position: relative;
    float: left;
    text-decoration: none;
    margin-left: -1px;       
    border: 1px solid; 
}
.defaultLink:hover{
    text-decoration: none;
    color: #666;
}
.active {
    z-index: 3;
    cursor: default;    
}
.active:hover{
    color: #fff
}