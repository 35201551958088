.container {
    position: relative; 
}
.alert {
    padding: 1rem 1.25rem;
}
.close {
    position: absolute;
    top: 5px;
    right: 5px;
    padding-left: 5px;
    font-size: 14px;
    cursor: pointer;
}