.h1 {
    font-size: 5mm;
    color: #000;
    text-transform: initial; 
}
.headerSpace {
    height: 22mm;
    margin-bottom: 5mm;    
}
.footerSpace {
    margin-top: 5mm;
}
.table {
    background-color: #fff;
    color: #000;
    font-family: Calibri, sans-serif !important;
    padding: 0;
    width: 210mm;
    overflow: hidden;
}
.content {
    padding: 0;
    margin: 0 15mm 0 25mm;
}
.content ol {
    padding: 0;
    margin: 0;
    width: 100%;
}
.content thead {
    font-size: 4mm;
    font-weight:700; 
    margin: 0;           
}
.content tbody {
    font-size: 4mm;    
}
.content tr {
    padding: 0 !important;
    border: none !important;    
}
.content th {
    text-align: center;
    padding: 5px 0 !important;
    border-right: 1px solid #333 !important;
    border-bottom: 1px solid #333 !important;
    border-top: 1px solid #333 !important;    
}
.content th:first-child {
    border-left: 1px solid #333 !important;    
}
.content td {
    padding: 5px 0 !important;
    border-right: 1px solid #333 !important;
    border-bottom: 1px solid #333 !important;
    border-top: none !important;
    text-align: center;    
}
.content td:first-child {
    border-left: 1px solid #333 !important;    
}
.content:first-child {
    margin-top: -3mm;    
}
.wrapper {
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: collapse;
}
.wrapper p {
    font-size: 4mm;
    display: block !important;
    margin: 2mm 0;
    color: #000;
    page-break-inside: avoid !important;
}
.wrapper h2 {
    font-size: 5mm !important;
    margin: 2mm 0; 
    border-bottom: 1px solid #000;
    width: fit-content;     
    color: #000;
    text-transform: initial; 
}
.avoidBreak {
    page-break-after: always;
    break-after: always;    
    page-break-inside: avoid; 
    break-inside: avoid;    
    width: 100%;
}
.avoidBreak:last-child{
    page-break-after: avoid;
    break-after: avoid; 
}
.break{
    page-break-before: always;
}
.break:last-child{
    page-break-after: auto;
    break-after: auto;
}
    
@media print {
    @page {
        page-break-after: always;
        size: a4;
    }
    .wrapper {
        position: relative;
        visibility: visible;
        width: 210mm;
        height: 297mm;
        overflow: visible;
    }
    .table {
        overflow: visible;
    }
    .content {
        margin: 0 15mm 0 25mm;
        position: relative;
        print-color-adjust: exact !important;
    }
    .h1 {
        margin: 2mm 0;
        padding-left: 5mm;
        margin-left: 5mm;
    }
}