.space {
    margin: 0;
}
.wrapper {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}
.item span {
    margin-right: 7px;
}
.h4 {
    color: inherit;
    text-transform: none;
    font-size: 15px;
}
.h4 span {
    font-weight: 700
}