.columnSpace {
    margin: 10px 0;
}
.bottomMargin {
    margin: 0 0 10px 0;
}
.topMargin{
    margin: 10px 0 0 0;
} 
.fullWidth {
    width: 100%;
}
.flexEnd {
    display: flex;
}
.flexEnd div:first-child {
    align-self: flex-end;
}
.flexColumn {
    flex-direction: column;
}
.infoCss {
    max-width: inherit;
}