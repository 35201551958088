.wrapper {
    margin: 20px 0;
}
.h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
    margin-top: 25px;
}
.p{
    margin: 5px 0;
}
.error{
    color: #e74c3c;
}
.result{
    font-size: 15px;
}
.typicalLength {
    margin: 25px 0;
}
.typicalDescription {
    margin: 15px 0 0 0;
}
.underline {
    text-decoration: underline;
}