.modal {
    max-width: 650px;
    left: 50%;
    transform: translateX(-50%)
}
.floatWrapper {
    text-align: right;
    margin-right: 0;    
}
.alert {
    font-size: 15px;
    margin-bottom: 35px;
    text-transform: initial;
    text-align: justify;
}
.tr td {
    padding: 3px;
}
.button {
    margin: 20px 0 0 0;
}