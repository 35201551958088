.wrapper {
    margin: 10px 0;
    position: relative;
    float: right;
    z-index: 1;
}
.wrapperLeft {
    margin: 10px 0;
    position: relative;
    z-index: 1;
}
.span {
    padding-right: 10px;    
}
.dropdownItem {
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    color: inherit;
}
.dropdownItem svg {
    position: relative;
    top: 2px;    
}
.dropdownItem:active, .dropdownItem:focus {
    background-color: inherit;
    color: inherit;
    border: none;
}
.dropdownItem:hover{
    color: inherit;    
}
.dropDown {
    padding: 0;    
}
.dropDown a {
    padding: 0.25rem 1rem;
}
.dropDown div {
    padding: 0;    
    right: 0 !important;
}
.dropDown button {
    padding: 3px 10px;
    box-shadow: none !important;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;    
    font-size: 15px;
    font-weight: 600;
    color: inherit
}
.dropDown button::after{
    vertical-align: middle;
    margin-left: 7px;
}
.dropdownMenu{
    padding: 0;
    right: 0;    
}
.floatWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}
.floatWrapperLeft {
    position: relative;
    display: flex;
    align-items: center;
}