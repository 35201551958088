.value {
    position: absolute;
    left: 90mm;
    display: block;
    top: 0;
}
.wrapper p {
    position: relative;
}
.table {
    margin: 0 !important;    
}
.symbols {
    page-break-inside: auto;
}
.margins {
    margin: 0 0 5mm 0;
}