.wrapper{
    min-height: 100%;    
    position: relative;
}
.description {
    z-index: 2;
    width: 100%;
}
.item {
    display: block;
    font-size: 3.5mm;
    color: #000;
}
.spot {
    position: absolute;
    bottom: 2px;
    right: 5px;
    color: #000;
    font-size: 3.5mm;
    font-weight: 500;    
}
.layer {
    position: relative;
    border-bottom: 1px solid #333; 
}
.layer:first-child {
    border-radius: 10px 10px 0 0;
    border-top: none;
}
.layer:last-child {
    border-radius: 0 0 10px 10px;
    border-bottom: none;
}
.hiddenLayer {
    display: none;
}

.listWrapperVisible {
    width: auto;
    visibility: visible;
    position: absolute;
    right: 110px;
    top: 50%;
    transform: translateY(-50%);  
}