.table {
    margin: 0 0 5mm 0 !important;    
}
.table th {
    font-size: 4mm !important;
}
.table td {
    font-size: 4mm !important;
}
.wrapper {
    page-break-inside: avoid;
    display: inline-table;
}
.wrapper h2 {
    text-align: left;
    display: initial;
}
.margins {
    margin: 2mm 0;
}