.loadButton {
    margin: 5px 5px 5px 0;
}
.modalDialog {
    width: 100%;
    max-width: 900px;
}
.modalDialogEditProjectName {
    width: 100%;
    max-width: 700px;
}
.modalDialogInfo {
    width: 100%;
    max-width: 600px;
}
.h2Title{
    font-size: 15px;
    text-align: left;
    margin: 0;
}
.button {
    margin: 15px 0;
    float: right;
}
.modalDialog {
    width: auto !important;
}
.ul {
    list-style: decimal;
    padding: 5px 0 5px 5px;
    font-size: 15px;
    margin: 0;
}
.ul li {
    list-style: none;
}
.ul li {
    list-style: none;
}
.ul li .projectWrapper p {
    margin: 0
}
.ul li .projectWrapper {
    margin: 10px 0;
}
.ul li:last-child .projectWrapper {
    margin: 10px 0 0 0;
}
.ul li:first-child .projectWrapper {
    margin: 0;
}
.projectWrapper {
    display: flex;
    justify-content: space-between;    
}
.inlineNavbar {
    display: flex;
    justify-content: space-between;
}
.p {
    cursor: pointer;
    margin: 0;
}
.currentProject {
    font-weight: 700;
    text-decoration: underline;
}
.action {
    cursor: pointer;
    margin: 0 8px;
}
.action:last-child{
    margin-right: 0;
}
.action:first-child{
    margin-left: 0;
}
.center {
    text-align: center;
    margin: 25px 0;
}
.flexTitle {
    display: flex;
}
.flex{
    display: flex;
}
.select {
    margin: 5px 5px 5px 0;
    max-width: 230px;
}
.pointer {
    cursor: pointer;
}

.checkbox {
    margin: 0 5px;
}
.table th {
    padding: 0.5rem;
}
.table td {
    padding: 5px 0;
}
.row {
    margin: 25px 0;
}
.row:first-child {
    margin-top: 0;
}
.infoWrapper p {
    margin: 5px 0 10px 0;
    display: block;
}
.confirmModal{
    width: 550px;
    max-width: 550px;
    text-align: center;
}
.itemsPerPage::after { 
    content: " ";
    display: block; 
    height: 0; 
    clear: both;
 }
 .centerCol{
    align-content: center;
    align-items: center;
    align-self: center;
 }