.modal {
    left: 50%;
    transform: translateX(-50%)
}
.modalDialog{
    width: auto !important;
    max-width: 650px;
}

.projectWrapper {
    display: flex;
    justify-content: space-between;    
}

.p {
    font-size: 15px;    
}
.row {
    margin: 20px 0;
}
.row:first-child {
    margin-top: 0
}
.row:last-child {
    margin-bottom: 0
}