
.thead th {
    padding: 10px;
}
.tbody td {
    padding: 0;
    background: inherit;
}
.titleWrapperSoilTable {
    display: inline-flex;
}
.tbody input {
    background: inherit;
}
.actionsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;    
}
.actionsChart {
    margin: 20px 0;
    display: flex;
}
.actions {
    margin: 20px 0;
}
.inputFileWrapper {
    width: auto;   
}
.scale {
    margin-bottom: 25px;
}
.tdLeft {
    text-align: left !important;
}
.tdLeft input {
    text-align: left !important;
}
.dialog {
    width: 100%;
    max-width: fit-content;
}
.cptChart {
    width: 100%;
    transform-origin: 0 0;
    position: relative;
    pointer-events: none;
    user-select: none;
}
.overflowAuto {
    overflow: auto;
    position: relative;
    margin: 10px 0;
}
.flexAlign {
    display: flex;
    align-items: flex-end;
}
.appendix {
    margin: 30px 0;
    text-align: center;
}
.appendix p {
    display: block;
}
.appendix svg {
    color: red;
    font-size: 25px;
}
.row {
    margin: 0 0 25px 0;
}
.stepByStep h1 {
    font-size: 18px;
    font-weight: 700;
}
.stepByStep ol {
    padding-left: 12px;
}
.stepByStep ol li {
    margin-bottom: 15px;
}

.stepByStep ol li p svg {
    color: #01b169;
    margin-left: 7px;
    font-size: 16px;
}
.horizontal {
    width: 100%;
    height: 2px;
    background-color: red;
    position: absolute;
    cursor: crosshair;
}
.horizontalScale {
    width: 0px;
    height: 2px;
    background-color: black;
    cursor: crosshair;
    position: absolute;
}
.verticalScale {
    width: 2px;
    height: 0px;
    background-color: black;
    cursor: crosshair;
    position: absolute;
}
.vertical {
    height: 100%;
    width: 2px;
    background-color: red;
    position: absolute;
    cursor: crosshair;
}

.caret {
    position: absolute;
    right: 0px;
    top: -6px;
    display: block;
    width: 14px;
    height: 14px;
    border-top: 2px solid red;
    border-left: 2px solid red;
    transform: rotate(135deg);
}
.line {
    position: absolute;
    display: block;
    width: 2px;
    height: 14px;
    top: -6px;
    background-color: black;
}
.lineVertical {
    position: absolute;
    display: block;
    width: 14px;
    height: 2px;
    top: 0px;
    left: -6px;
    background-color: black;
}
.verticalCaret {
    position: absolute;
    right: -5px;
    bottom: 1px;
    display: block;
    width: 14px;
    height: 14px;
    border-top: 2px solid red;
    border-left: 2px solid red;
    transform: rotate(225deg);
}
.relative {
    position: relative;
    cursor: crosshair;
    overflow: auto;
}
.point {
    width: 8px;
    height: 8px;
    border: 3px solid red;
    position: absolute;
    cursor: crosshair;
}
.layer {
    height: 2px;
    background-color: red;
    position: absolute;
    cursor: crosshair;
}
.titleWrapper{
    margin: 10px 0;
    display: flex;
}
.new {
    color: red;
    margin-left: 10px;
    cursor: pointer;
}
.new1 {
    color: red;
    margin-right: 10px;
    padding: 5px 0;
    cursor: pointer;
}
.soilType {
    display: block !important;
    margin: 0;
    position: absolute;
    right: 5px;
    bottom: 2px;
}
.editLayerType{
    display: flex;
}
.lp {
    display: block !important;
    margin: 0;
    margin-right: 20px;
    padding: 5px 0;
}
.lpTitle {
    display: block !important;
    margin-bottom: 5px;
}
.radioWrapper {
    max-width: 120px;
    padding: 5px 0;
}
.radioWrapper1 {
    max-width: 100px;
    padding: 5px 0;
}
.radioText {
    top: 6px;
}