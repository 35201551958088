.checkbox {
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:100%;
    height:100%;
    opacity:0;
    z-index:2;
    border:none;
    border-radius:50px;
    cursor: pointer;    
}
.icon {
    position:absolute;
    top:4px;
    left:5px;
    width:15px;
    height:15px;
    border-radius:50%;
    transition:left 0.4s ease-out;
    box-shadow:1px 1px 3px rgba(0,0,0,0.5);    
}
.switch {
    margin: auto;
    position:relative;
    width: 50px;
    height:25px;
    border-radius:30px;
    font-size:14px;
    font-weight:700;
    background-color: #fff;
    transition: all 0.5s ease-in;
    border: 1px solid;
    border-color: #282e38;    
}
.switch svg {
    color: #282e38;
    position: absolute;
    right: 9px;
    top: 4px;
    font-size: 15px;    
}
.switch .icon {
    background-color: #282e38;
}
.open {
    background-color: #282e38;
}
.open svg {
    color: #fff;
    left: 8px;
    right: auto;
}
.open .icon {
    left: 28px;
    background-color: #fff;    
}