.row {
    margin-top: 30px;
    margin-bottom: 30px;    
}
.row:first-child {
    margin-top: 5px;    
}
.table td {
    padding: 5px !important;
}
.min120{
    min-width: 120px;
}