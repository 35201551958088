.h2 {
    text-transform: uppercase;
    margin: 10px 0;
    font-size: 15px;
    text-align: left;
}
.h4 {
    margin: 0;
    font-size: 15px;
    text-align: left;
}
.infoIcon {
    margin-left: 10px;
}
.info {
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 450px;
    z-index: 1;
    display: none;
}
.info:hover {
    display: block;
}
.infoIcon:hover + .info {
    display: block;
}
.wrapper{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: inherit;
}