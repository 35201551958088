.wrapper{
    padding: 25px 0 15px 0;
    width: 100%;
}
.wrapper h1, h2, h3, h4, h5, h6 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 100;
    color: inherit;
    font-size: 26px;
}
.subtitle {
    color: inherit;
    text-align: center;
    font-size: 15px;    
}