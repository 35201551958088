.thead th {
    padding: 10px;
}
.tbody td {
    padding: 0
}
.actionsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;    
}
.actions {
    margin: 20px 0;    
}
.tdLeft {
    text-align: left !important;
}
.tdLeft input {
    text-align: left !important;
}
.select {
    outline: none;
    width: 100%;
    border-radius: 5px;
    padding: 7px 10px; 
    cursor: pointer;
}