.menuContainer {
    width: 100%;
}
.wrapper {
    width: 100%;
    z-index:5;    
}
.mainMenu {
    z-index: 999;
    padding: 0;
}
.menuContainer ul li a {
    display: flex;
    color: inherit;
    font-size: 15px;
}
.menuContainer ul li a svg {
    position: relative;
    top: 3px;
    font-size: 18px;
    margin-right: 5px;    
}
.navbarNav {
    flex-direction: row; 
    justify-content: flex-end;
    width: 100%;
}
.navbarNav > li > a {
    margin: 15px 0px;
    padding: 0;
    transition: all 0.5s ease-in-out;     
}
.siteNavigation {
    font-size: 15px;

    padding: 10px;
}
.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.siteNavigation div:first-child {
    width: 100%;
}
.siteBrandingText {
    color: inherit;
}
.siteBrandingText img {
    width: 165px
}
.siteBrandingText h1 a:hover, .siteBrandingText h1 a {
    font-size: 28px;
    text-transform: none;
    text-decoration: none;
    color: inherit;
}
.contact, .licence, .logOut{
    width: auto !important;
    background-color: transparent;
    color: inherit;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    margin: 15px 0px;
    display: flex;
    padding: 0 ;
    font-size: 15px;
}
.licence{
    cursor: default;
    margin: 0 10px;
}
.noPadding {
    padding: 0 !important;
    border-radius: 15px !important;
}
.logOut {
    cursor: pointer
}
.contact svg,.licence svg, .logOut svg {
    font-size: 20px;
    margin-right: 5px;
}
.contact * {
    color: inherit
}
.contact:hover, .logOut:hover{
    text-decoration: underline;
}
.loadingWrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.75;
    z-index: 9999;
}
.subsLeft {
    display: none;
}
@media (max-width: 992px){
    .licence{
        justify-content: center;
        background-color: transparent !important;
        padding: 0 !important;
        margin: 15px 0;
    }
    .contact, .logOut, .account {
        justify-content: center;
        width: 100% !important;
    }
    .subsLeft {
        display: block;
    }
    .menuContainer {
        position: absolute;
        top:0 ;
        left: 0;
        transform: translate3d(-100%, -100%, 0);
        opacity: 0;
    }
    .navbarNav {
        text-align: left;
        display: block;
    }    
    .openPanel span:nth-child(1) {
        top: 0px;
        width: 50%;
        left: 0;
        transform-origin: left center;
    }
    .openPanel.clicked  span:nth-child(1){
        transform: rotate(45deg);
        top: -2px;
        left: 3px;
        width: 100%;
    }
    .openPanel span:nth-child(2) {
        top: 7px;
        width: 100%;
        left: 0;
        transform-origin: left center;
    }
    .openPanel.clicked span:nth-child(2) {
        width:0%;
    }
    .openPanel span:nth-child(3) {
        top: 14px;
        width: 50%;
        right:0;
        transform-origin: left center;
    }
    .openPanel.clicked  span:nth-child(3){
        transform: rotate(-45deg);
        top: 14px;
        left: 3px;
        width: 100%;
    }
    .openPanel span {
        display: block;
        position: absolute;
        height: 3px;
        opacity: 1;
        transition: .25s ease-in-out;
    }
    .openPanel {
        width: 24px;
        height: 28px;
        position: absolute;
        top: 30px;
        right: 20px;
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 1000;
    }
    .siteNavigation .navContainer {
        padding-right: 50px;
        background-color: inherit;
    }
    .openNav {
        z-index:3;
        width: 100%;
        padding: 10px;
        white-space: normal;
        border-right: none;
        transform: translate3d(0, 0, 0);
        opacity: 1;
        top: 100%;
    }
}


@media (min-width: 993px){
    .navbarNav > li > a {
        padding: 0px 10px;   
    }
    .contact, .licence, .logOut {
        padding: 0px 10px;   
    }
    .logOut{
        padding: 0 0 0 10px;
    }
}
