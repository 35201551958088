.modal {
    width: 900px;
    max-width: 900px;
}
.modalOverflow{
    overflow: auto !important;
}

.table td {
    padding: 5px 0;
}
.row {
    margin: 20px -15px;
}
.rightButton {
    text-align: right;
}
.flexCheckbox {
    display: flex;
    justify-content: center;
}
.noUsersCenter{
    text-align: center;
    font-style: italic;
    display: block !important; 
    width: 100%;
    margin: 20px 0 !important;
}