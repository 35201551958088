.wrapper {
    height: 130mm;
    position: relative;    
    border: 1px solid #000;
    width: 100%;
    font-size: 3mm !important; 
    border-radius: 10px;
} 
.chartWrapper {
    border: 1px solid #bbb;
    padding: 0 5mm 5mm 5mm;
    margin-bottom: 5mm;
    width: 100%;
    overflow: hidden;
} 
.wrapper p {
    font-size: 3mm !important;
}
.spot {
    position: relative;
    font-weight: 500;    
    font-size: 3.5mm !important;
}
.avoidBreak{
    page-break-inside: avoid;
}
.pile {
    border: 1px solid #666;
    position: relative;
    width: 8mm;
    height: 100%;
    background: #888;
    border-radius: 3px;
    flex-shrink: 0;
    margin: 0 13mm 0 0;
}
.pileLength {
    height: 100%
}
.pileLength p {
    writing-mode: vertical-lr;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 1.5mm;
    right: 0;
    color: #000;
}
.pileWrapper {
    position: absolute;
    padding-left: 5mm;
    z-index: 1;
    display: flex;
    
    width: 100%;
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 5mm;
    min-width: 20mm;
}
.pileShape {
    width: 7mm;
    height: 7mm;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 1px;
    left: 1px;
    background: #aaa;
}
.circle {
    border-radius: 50%;
}
.pileSpot {
    color: #000;
}
.pileHeadSpot {
    position: absolute;
    top: 0;
    left: 9.5mm
}
.pileBaseSpot {
    position: absolute;
    bottom: 0;
    left: 9.5mm;
}
.results {
    width: 100%;
    height: 100%;
    position: relative;
}

.capacityPoint {
    position: absolute;
    margin-top: -2mm;
    margin-left: -1.5mm;
    width: 4mm;
    height: 4mm;
}
.capacityPoint svg {
    position: relative;
    top: -3px;
}
.validDepth {
    color: #000;
}
.wrongDepth {
    color: #e74c3c;
}
.warningDepth {
    color: #ffa500
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.verticalCaret {
    position: absolute;
    right: -1.5mm;
    bottom: 0;
    display: block;
    width: 3mm;
    height: 3mm;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(225deg);
}
.verticalAxis {
    width: 1px;
    height: calc(100% + 2mm);
    background: #000;
    position: relative;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 4mm;
    background-color: #000;
}
.value {
    color: #000;
    position: relative;
    top: 5px;
    left: -13px;
}
.spanValue {
    position: absolute;
    left: 105mm; 
}
.underline{
    text-decoration: underline;
}
.correctDepthClass{
    color: #000 !important;
    padding-left: 4mm;
}
.axisDescription {
    color: #000;
    transform: rotate(-135deg);
    position: relative;
    top: 8mm;
    left: 7mm;
}
.axisVerticalDescription {
    color: #000;
    transform: rotate(-225deg);
    position: relative;
    top: 1mm;
    left: 0;
}
.currentHorizontalValue {
    position: absolute;
    height: 1px;
    border-bottom: 1px solid #000;
    opacity: 0.3;
}
.currentVerticalValue {
    top:0;
    position: absolute;
    width: 1px;
    border-right: 1px solid #000;
    opacity: 0.3;
}
.resultsOverview{
    height: 100%;
}
.sticky {
    position: sticky;
    top: 5px;
}
.sticky p {
    margin: 5px 0;
}
.sticky p:first-child {
    margin: 0 0 5px 0;
}
.warnings h3 {
    text-align: left;
    font-size: 4mm;
    text-transform: uppercase;
}
.warnings ol {
    padding-left: 15px;
} 
.warnings ol li p {
    margin: 5px 0;
}
.title {
    margin: 0;
    padding: 10px 0;
}
.buttonMarginLeft { 
    margin: 5px 3px 15px 0;
}
.buttonMarginRight {
    margin: 5px 0 15px 3px;
}
.title {
    width:100%;
    text-align: center;
}
.title h2 {
    font-size: 5mm;
    width: 100%;
    font-weight: 700;
    text-decoration: none;
    margin: 5mm 0;
    color: #000;
    text-transform: initial; 
    border-bottom: none;
}
.capacityDescription p {
    font-size: 4mm !important;
    position: relative;
}
.layersWrapper > div:first-child {
    border-radius: 10px 10px 0 0;
}
.layersWrapper > div:last-child {
    border-radius: 0 0 10px 10px;
}