.select {
    outline: none;
    width: 100%;
    padding: 6px 10px; 
    cursor: pointer;
}
.label {
    color: inherit;
    margin-bottom: 0;
}
.wrapper {
    position: relative;
    outline: none;   
}
.wrapperInvalid {
    outline: 1px dashed red;   
}

.title {
    color: inherit;
    width: max-content;
    margin-bottom: 0;
    margin-top: 0;
}
.titleWrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.infoIcon {
    margin-left: 10px;
}
.info {
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 300px;
    z-index: 1;
    display: none;
}
.info:hover {
    display: block;
}
.infoIcon:hover + .info {
    display: block;
}