.wrapper {
    margin: 0;
}
.days {
    font-size: 15px;
    display: block;
    margin: 10px 0 20px 0;
    text-transform: lowercase; 
}
.plan {
    font-size: 20px;
    display: block;
    margin: 10px 0 20px 0;
    text-transform:none
}
.date {
    font-size: 30px;
    display: block;
    margin: 10px 0 10px 0;
    text-transform: lowercase;
}
.h4 {
    font-size: 15px;
}
.buttonWrapper{
    width: 100%;
    text-align: center;    
}
.modal {
    width: auto;
    max-width: 650px;
    padding-top: 20px;
}
.modal1 {
    width: auto;
    max-width: 700px;
    padding-top: 20px;
}
.modalOverflow{
    overflow: auto !important;
}
.singlePlan {
    text-align: center;
}
.row {
    margin: 30px 0;
}
.row1 {
    margin: 40px 0 10px 0;
}
.row2 {
    margin: 15px 0;
}
.row:first-child {
    margin-top: 0;
}


.h4Licences {
    font-size: 15px;
    text-transform: none;
}
.spanLicences { 
    text-transform: lowercase;
}
.radioForm {
    display: flex;
    justify-content: space-between;
}
.center{
    text-align: center;
}
.buyNowButton{
    font-size: 18px;
}
.summary{
    font-size: 40px;
}
.summary span {
    font-size: 20px;
    text-transform: lowercase;
}
.newLicence{
    text-align: center;
    font-size: 18px;
    display: block !important;
    margin: 5px;
}
.p {
    text-transform: none;
    font-size: 18px;
    text-align: center;
    display: block !important;
    margin: 10px 0;
    font-weight: 700;
}
.sectionTitle{
    text-transform: none;
    text-align: center;
    width: 100%;
}

.flexCheckbox {
    margin: 0;
    display: flex;

}
.flexCheckbox p {
    width: 100%;
    cursor: pointer;
}
.checkboxWidth {
    width: auto;
}