.section{
    margin: 0 0 35px 0;
}
.section:last-child{
    margin: 0 0 50px 0;
}
.alerts{
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10000;
}
.loadingWrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.75;
    z-index: 9999;
}
.colMargin{
    margin-bottom: 40px;
}