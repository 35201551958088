.value {
    position: absolute;
    left: 120mm;
    display: block;
    top: 0;
}
.value1 {
    position: absolute;
    left: 50mm;
    display: block;
    top: 0;
}
.wrapper p {
    position: relative;
}