.row {
    margin: 30px 0;
}
.rowWrapper {
    margin: 0;
}
.colWrapper {
    padding: 0;
}
.row:first-child {
    margin-top: 0;
}
.radioForm {
    display: flex; 
    justify-content: space-between;
}
.summary{
    font-size: 36px;
}
.summary span {
    font-size: 20px;
    text-transform: lowercase;
}
.newLicence{
    text-align: center;
    font-size: 15px;
    display: block !important;
    margin: 5px;
}
.center{
    text-align: center;
}
.buyNowButton{
    font-size: 15px;
}
.radioWrapper input:checked + .radio:before,
.radioWrapper input:not(:checked) + .radio:before { 
    background: #fff !important;
    border: 1px solid #bbb
}
.radioWrapper input:checked + .radio:after, 
.radioWrapper input:not(:checked) + .radio:after {
    background: #b3b3b3 !important;
}
.modalH2 {
    margin: 10px 0 20px 0;
    font-size: 20px;
    font-weight: 700;
    text-decoration: underline;
}
.h4Center {
    text-transform: none;
    font-size: 15px;
    text-align: center;
}
.modal {
    width: 650px;
    max-width: 650px;
}
.modal :global .modal-content{
    background-color: #f1f1f1;
}
.modalOverflow{
    overflow: auto !important;
}
.labelText {
    color: #000 !important;
    font-weight: 700;
}

.invoiceDialog :global .modal-content {
    background-color: #f1f1f1 !important;
}
.invoiceDialog :global input {
    color: inherit;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 7px;
}
.invoiceDialog :global .custom-radio label{
    background-color: #fff;
}
.invoiceDialog :global .custom-radio label div:first-child {
    background-color: #999;
}
.invoiceDialog :global .custom-radio label::before{
    border: 1px solid #bbb
}
.invoiceDialog :global .custom-radio label::after{
    border-color: #999;
    background: #999;
}
.invoiceDialog :global input:checked + label {
    background: #fff;
}
.invoiceDialog :global input:checked + label div:first-child {
    background: #3b4452
}


.invoiceDialog :global .custom-checkbox label{
    background-color: #fff;
}
.invoiceDialog :global .custom-checkbox label div:first-child {
    background-color: #999;
}
.invoiceDialog :global .custom-checkbox label::before{
    border: 1px solid #bbb
}
.invoiceDialog :global .custom-checkbox label::after{
    border-color: #999
}
@media (max-width: 992px) {
    .hideMd{
        display: none;
    }
 }
