.ulList {
    padding-left: 0;
    padding-top: 5px;
    margin-bottom: 0;     
}
.ulListIsWarning {
    color: orange;
}
.ulListInvalid {
    color: red
}
.ulList li {
    list-style: none;    
}
.fullWidth {
    width: 100%;
}
.fullWidth input {
    border: none;
}
.inputField, .textarea {
    width: 100%; 
    padding: 5px 10px;
}
.inputField::-webkit-inner-spin-button, .textarea::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField::-webkit-outer-spin-button, .textarea::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0; 
}
.inputField:focus, .textarea:focus {
    outline: none;
}
.label {
    color: inherit
}
.group {
    display: table;
    border-collapse: collapse;
    width:100%;
    outline: none;
    border: none;    
}
.groupIsWarning {
    outline: 1px dashed orange;
}
.groupInvalid {
    outline: 1px dashed red;
}
.group div {
    display: table-cell;
    vertical-align: middle; 
    border-radius: 5px 0 0 5px;
}
.groupIcon {
    padding: 0 8px;
    cursor: pointer;
    text-align: center;    

    align-content: center;
    align-items: center;
    align-self: center;
}
.titleWrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.infoIcon {
    margin-left: 10px;
}
.info {
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 300px;
    z-index: 1;
    display: none;
}
.info:hover {
    display: block;
}
.info img {
    width: 100%;
    margin-bottom: 10px;
}
.wrapper {
    position: relative;
    width: 100%;
}
.infoIcon:hover + .info {
    display: block;
}
.title {
    color: inherit;
    width: max-content;
    margin-bottom: 0;
    margin-top: 0;
}