.wrapper {
    cursor: default;
    padding: 15px !important;
    margin: 5px 0;
}
.h2 {
    font-size: 28px;
    text-align: left;
    font-weight: 700;
}
.h4 {
    font-size: 15px;
    text-align: left;
}
.icon svg {
    font-size: 24px;
}
.row{
    margin-left: 0;
    margin-right: 0;
}
.col {
    padding: 0;
}
.flexRight{
    display: flex;
    justify-content: flex-end;
}