.col {
    padding: 0;
}
.col p {
    margin: 5px 0;
}
.row {
    margin: 0;
}
.flex {
    display: flex;
    align-items: center;
}
.flex svg {
    color: #cc1525;
    margin-right: 10px;
}