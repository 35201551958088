.loadButton {
    margin: 5px 5px 5px 0;
}
.modalDialogInfo {
    width: 100%;
    max-width: 600px;
}
.button {
    margin: 5px;
    float: right;
}
.ul {
    list-style: decimal;
    padding: 5px 0 5px 5px;
    font-size: 15px;
    margin: 0;
}
.ul li {
    list-style: none;
}
.ul li .projectWrapper p {
    margin: 0
}
.ul li .projectWrapper {
    margin: 10px 0;
}
.ul li:last-child .projectWrapper {
    margin: 10px 0 0 0;
}
.ul li:first-child .projectWrapper {
    margin: 0;
}
.ul li {
    list-style: none;
}
.projectWrapper {
    display: flex;
    justify-content: space-between;    
}
.projectWrapper .p {
    cursor: pointer;
    margin: 0;
}
.tdLeft {
    text-align: left !important;
    padding-left: 5px !important;
}
.tdLeft input {
    text-align: left !important;
}
.save {
    margin: 0  0 0 5px;
}
.inlineNavbar {
    display: flex;
    justify-content: space-between;
}
.editSoilProfile{
    cursor: pointer;
    margin-right: 15px;
    height: fit-content;
}
.editSoilProfile:last-child{
    margin-right: 0;
}
.center {
    text-align: center;
    margin: 25px 0;
}
.currentProfile {
    font-weight: 700;
    text-decoration: underline;
}
.profileActions {
    display: flex;
}
.h2Title{
    font-size: 15px;
    text-align: left;
    margin: 0;
}
.infoWrapper p {
    margin: 5px 0 10px 0;
    display: block;
}