.wrapper {
    height: 650px;
    position: relative;    
    border: 1px solid #aaa;
    border-radius: 10px;
    margin-bottom: 25px;
}
.sheetPileLength {
    height: 100%
}
.sheetPileLength p {
    writing-mode: vertical-lr;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    color: #000;
}
.p {
    font-size: 15px;    
}
.horizontalAxis {
    height: 1px;
    background: #000;
    position: relative;
}
.pileWrapper {
    position: absolute;
    padding-left: 30px;
    padding-top: 15px;
    z-index: 1;
    display: flex;
    width: calc(100% - 20px);
    top: 0;
    height: calc(100% - 15px);
}
.pileWrapperCapacityView {
    position: absolute;
    padding-left: 20px;
    z-index: 1;
    display: flex;
    width: calc(100% - 20px);
    top: 0;
    height: calc(100% - 15px);
}
.results {
    width: 100%;
    height: 100%;
    position: relative;
}
.caret {
    position: absolute;
    right: 2px;
    top: -4px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(135deg);
}
.verticalCaret {
    position: absolute;
    right: -4px;
    bottom: 1px;
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(225deg);
}
.spotWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0px 1px 0 9px;
    min-width: 53px;
}
.pileSpot {
    color: #000;
    line-height: 16px;
    display: block;
    text-align: center;
}
.spanBlock {
    display: block;
    font-size: 14px;
}
.capacityPoint {
    position: absolute;
    font-size: 14px;
    margin-top: -7px;
    margin-left: -7px;
    width: 14px;
    height: 14px;
}
.capacityPoint svg {
    position: relative;
    top: -1px;
}
.verticalAxis {
    width: 1px;
    height: calc(100% + 15px);
    background: #000;
    position: relative;
}
.axisCoord {
    position: absolute;
    display: block;
    top: -5px;
    width: 1px;
    height: 10px;
    background-color: #000;
}
.axisCoordVert {
    position: absolute;
    display: block;
    left: -5px;
    width: 10px;
    height: 1px;
    background-color: #000;
}
.value {
    color: #000;
    font-size: 12px;
    position: relative;
    top: 9px;
    left: -12px;
}
.valueVert {
    color: #000;
    font-size: 12px;
    position: relative;
    top: -9px;
    left: -15px;
}
.axisDescription { 
    color: #000;
    font-size: 14px;
    transform: rotate(-135deg);
    position: relative;
    top: -10px;
    left: 35px;
}
.axisVerticalDescription {
    color: #000;
    font-size: 14px;
    transform: rotate(-225deg);
    position: relative;
    top: 5px;
    left: 0px;
}
.title {
    margin: 0;
    padding: 10px 0;
}
.summary {
    border: 1px solid;
    border-color: inherit;
    padding: 10px;
    margin-top: 25px;
    border-radius: 10px;
}
.summary h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
}
.warnings {
    margin-top: 25px;   
}
.warnings h3 {
    text-align: left;
    font-size: 15px;
    text-transform: uppercase;
}
.warnings ol {
    padding-left: 15px;
}
.warnings ol li p {
    margin: 5px 0;
}
.col {
    padding: 0;
}
.row {
    margin: 0;
}
.chartWrapperResult {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.chartWrapperResult svg {
    width: 100%;
    height: 100%;
}
.resultsOverview{
    height: 100%;
}
.svgLine {
    fill:none;
    stroke:#444;
    stroke-width: 1;
    stroke-dasharray: 0;
}
.svgWrapper{
    position: absolute;
    top: 0;
    left: 0;
    height: 650px;
    width: 100%;
}
.shaftChart {
    fill:none;
    stroke:black;
    stroke-width: 1;
    stroke-dasharray: 12;
}
.baseChart {
    fill:none;
    stroke:black;
    stroke-width: 1;
    stroke-dasharray: 4;
}

.totalChart {
    fill:none;
    stroke:black;
    stroke-width: 2;
}
svg .text {
    font-size: 12px;
}
svg .textLine {
    font-size: 14px;
}
.pile {
    border: 1px solid #666;
    position: absolute;
    top: 40px;
    right: 0px;
    width: 61px;
    height: 90px;
    background: #e6e6e6;
    border-radius: 3px;
    padding: 10px;
}
.pile p {
    font-size: 12px;
    text-align: center;
    margin: 0;
}
.pileH {
    width: 75px;
    padding: 5px;
}
.pileCapacityView {
    border: 1px solid #666;
    position: relative;
    width: 30px;
    height: 100%;
    background: #888;
    border-radius: 3px;
    flex-shrink: 0;
}
.pileShapeCapacityView {
    width: 26px;
    height: 26px;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    background: #aaa;
}
.pileShape {
    width: 35px;
    height: 35px;
    border: 1px solid #666;
    border-radius: 3px;
    background: #aaa;
}
.pileShapePref {
    width: 35px;
    height: 35px;
    border: 1px solid #666;
    border-radius: 3px;
    background: #aaa;
    position: relative;
    left: 2px;
}
.openEnded {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.openEndedInner{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e6e6e6;
    left: 4px;
    position: relative;
    top: 4px;
}

.openEndedCapacityView {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}
.openEndedInnerCapacityView {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #e6e6e6;
    left: 3px;
    position: relative;
    top: 3px;
    border: 1px solid #666;
}
.hWrapper {
    width: 27px;
    height: 47px;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 1px;
    left: 1px;
    background: #aaa;
}
.hTypeFlange {
    width: 23px;
    height: 7px;
    background: #e6e6e6;
    border: 1px solid #666;
    position: relative;
    left: 1px;
    top: 3px
}
.hTypeHeight {
    position: relative;
    top: 3px;
    left: 10px;
    width: 6px;
    height: 25px;
    background: #e6e6e6;
    border: 1px solid #666;
    border-bottom: none;
    border-top: none;
}
.hWrapperView {
    width: 100%;
    border-radius: 3px;
    background: transparent;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hWrapperView2H {
    display: flex;
    justify-content: center;
    align-items: center;
}
.hWrapperView2H .hWrapperView {
    position: relative;
    width: auto;
}

.hWrapperView .hTypeHeight {
    top: 0;
    left: 0;
    background: #888;
}
.hWrapperView .hTypeFlange {
    top: 0;
    left: 0;
    width: 30px;
    background: #888;
}
.hWrapperView2H .hWrapperView .hTypeFlange {
    width: 25px;
}
.hWrapperView2H .hWrapperView:first-child .hTypeFlange {
    border-right: none;
}
.hWrapperView2H .hWrapperView:last-child .hTypeFlange {
    border-left: none;
}
.wrapper2H{
    display: flex;
}
.wrapper2H .hWrapper {
    width: 50px;
    height: 47px;
    border: 1px solid #666;
    position: absolute;
    border-radius: 3px;
    top: 5px;
    left: -10px;
    background: #aaa;

    display: flex;
}
.wrapper2H .hWrapper div:first-child .hTypeFlange {
    border-right: none;
}
.wrapper2H .hWrapper div:last-child .hTypeFlange {
    border-left: none;
}
.circleCapacityView {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}
.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.buttonMarginLeft { 
    margin: 5px 5px 10px 0 !important;
}
.buttonMarginRight {
    margin: 5px 0 10px 5px !important;
}
.currentHorizontalValue {
    position: absolute;
}
.currentVerticalValue {
    top:0;
    position: absolute;
}
.charLoad {
    display: flex;
    align-items: center;
}
.charLoadWrapper {
    display: flex;
}
.charLoad p {
    margin: 0;
}
.validDepth {
    color: #000;
}
.wrongDepth {
    color: #e74c3c;
}
.currentSettleValue {
    color: black;
    font-size: 12px;
    margin: 0 0 0 8px;
}
.line {
    border-bottom: 1px solid #000;
    opacity: 0.3;
}
.currentForceValue {
    color: black;
    font-size: 12px;
    position: absolute;
    top: 35px;
    transform: rotate(-90deg);
    left: -10px;
    margin: 0;
}
.lineVertical {
    border-right: 1px solid #000;
    opacity: 0.3;
    height: 100%;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
}