.row {
    margin: 15px -5px;  
}
.row:first-child {
    margin-top: 15px;    
}
.rowMarginTop {
    margin-top: 30px;
    margin-bottom: 0px;
}
.rowMarginBottom {
    margin-top: 0px;
    margin-bottom: 30px;
}
.designTable {
    margin: 5px 0 20px 0;
}
.initLetter {
    text-transform: initial;
    font-weight: 700;
}
.center {
    justify-content: center;    
}
.incorrectSection {
    text-align: center;
    font-size: 16px;
}