.li {
    position: relative;
}
.li a {
    color: inherit; 
    padding: 5px 10px 5px 15px;
    display: flex;    
}
.li a:hover {
    text-decoration: none;    
}
.li a:hover::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    height: 100%; 
}
.li a i {
    margin-right: 15px;    
}
.li a i svg {
    width: 25px !important;
    height: 15px;    
}
.li a span{
    display: flex;
    justify-content: space-between;
    width: 100%;    
}
.active {
    text-decoration: none;

}

.active::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    height: 100%;    
}
.button {
    color: inherit;
    border: none;
    padding: 5px 10px 5px 20px;
    display: block;
    text-decoration: none;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
    text-align: left;    
}
.button:hover {
    color: #fff;
    text-decoration: none;    
}
.button i {
    color: #eee;
    margin-right: 15px;    
}
.button i svg {
    width: 25px !important;
    height: 15px;    
}
.textIcon{
    display: none;
}
@media (max-width: 576px)
{ 
    .textIcon {
        display: block;
        width: 100%;
        text-align: center;
        margin-right: 0 !important;
    }
    .li a {
        padding: 10px;        
    }
    .li a span {
        display: none;        
    }
    .button {
        padding: 10px;         
    }
    .button span {
        display: none;        
    }
}