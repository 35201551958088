.button svg{
    top: -4px;
}
.wrapper {
    position: relative
}
.wrapper ul {
    margin: 0;
    padding: 0;
}
.wrapper li {
    list-style: none;
    cursor: pointer;
    padding: 5px 10px 5px 15px;
}
.wrapper li:first-child{
    padding-top: 10px
}
.wrapper li:last-child{
    padding-bottom: 10px
}
.dropdown {
    position: absolute;
    right: 0;
    width: max-content;

    border: 1px solid;
    z-index: 10;

    border-radius: 5px;
}