.table {
    margin: 0 !important;    
}
.value {
    position: absolute;
    left: 70mm;
    top: 0;
    display: block;
}
.valueLegend{
    position: absolute;
    left: 15mm;
    top: 0;
    display: block; 
}
.underline{
    text-decoration: underline;
}
.wrapper p {
    position: relative;
}