.wrapper {
    transition: 0.25s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    z-index: 5;
    padding: 60px 0px 30px 0; 
}
@media (max-width: 576px)
{
    .wrapper {
        left: -60px;
        width: 50px;            
    }
}  
.activeLink {
    color: red
}

.overflowSidebar {
    overflow-y: hidden;
    height:100%;
    padding-bottom: 10px;    
}
.overflowSidebar::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #1b2431;    
}
.overflowSidebar::-webkit-scrollbar {
    width: 6px;
    background-color: #1b2431;    
}
.overflowSidebar::-webkit-scrollbar-thumb{
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #666;    
}
.overflowSidebar:hover {
    overflow-y: auto;    
}
.nav {
    overflow: hidden;    
}
.navContainer {
    width: 100%;    
}
.brand {
    display: block;
    margin-right: 0;
    line-height: 25px;
    width: 250px;
}
@media (max-width: 576px)
{ 
    .brand {
        display: none;
    }
}
.info {
    text-align: center;
}
.info span {
    display: block;
    font-size: 15px;
    color: inherit;
    font-weight: 600;    
}
.uppercase {
    text-transform: uppercase;    
}
.toggleOpen {
    width: 24px;
    height: 28px;
    position: absolute;
    top: 20px;
    left: 15px;
    cursor: pointer;
    z-index: 1000;    
}
@media (max-width: 576px)
{ 
    .toggleOpen {
        left: 10px;
    }
}
.toggleOpen span {
    display: block;
    position: absolute;
    height: 3px;
    opacity: 1;    
    transition: none;
}
.toggleOpen span:nth-child(1) {
    top: 0;
    left: 0;
    width: 50%;
    transform-origin: left center;    
}
.toggleOpen span:nth-child(2) {
    top: 7px;
    width: 100%;
    left: 0;
    transform-origin: left center;    
}
.toggleOpen span:nth-child(3) {
    top: 14px;
    width: 50%;
    right:0;
    transform-origin: left center;    
}
.switchMode {
    margin-top: 15px;    
}
.avatar {
    text-align: center;
    width: 60px;
    height: 60px;

    border-radius: 50%;
    margin: 0 auto 15px auto;
    position: relative;
}
.avatar img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}