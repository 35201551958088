.wrapper{
    margin-bottom: 25px;
}
.row {
    margin-top: 25px;
    margin-bottom: 25px;    
}
.wrapper .row {
    margin-top: 5px;
}
.row button {
    width: 100%;
}
.row:first-child {
    margin-top: 5px;
}
.row:last-child{
    margin-bottom: 5px;
}
.button {
    margin-left: 0;
    margin-right: 0;
}
.button div:first-child{
    justify-content: center;
}

.columnSpace {
    margin: 10px 0;
}

.tr td {
    padding: 0;
    font-size: 14px;
}
.tdLeft {
    text-align: left !important;
    padding-left: 5px !important;
}
.tr td select {
    width: auto;
}
.label {
    margin: 10px 0;
    color: inherit;
}
.modal {
    width: 990px;
    max-width: 990px;
}
.modalOverflow{
    overflow: auto !important;
}
.flexEnd {
    display: flex;
}
.flexEnd div:first-child {
    align-self: flex-end;
}
.flexColumn {
    flex-direction: column;
}