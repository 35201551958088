.wrapper {
    text-align: center;
    margin-top: 100px;
}
.button {
    font-size: 15px;
    margin-top: 30px;
    background-color: #1b3e6e;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    padding: 5px 15px;
    transition: all 0.5s ease;
}
.button:active {
    background-color: #1b3e6e !important;
    border-color: #1b3e6e !important;
    box-shadow: none !important;    
}
.button:hover {
    background-color: #1b3e90;
    border-color: #1b3e90;    
}
.button:focus {
    box-shadow: none !important;    
}
.button a {
    color: #fff;
}
.button a:hover {
    text-decoration: none !important;
}
.icon {
    margin-bottom: 25px;
}
.icon svg {
    font-size: 60px
}
