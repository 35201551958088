.modalDialogWrapper{
    max-width: 650px;
    z-index: 1051
}
.modalDialog {
    padding-top: 100px;
    margin-top: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;    
}
.modalContent {
    background-color: #f9f9f9;
    border: none;
}
.modalHeader {
    border-bottom: 1px solid #999;
    padding: 5px 15px;
    align-content: center;
    align-items: center;
}
.modalHeader button {
    outline: none !important;    
}
.modalTitle {
    font-weight: 700;
    font-size: 15px;    
}
.modalHeader button {
    font-size: 30px;
    padding: 15px;    
}
.modalFooter {
    border-top: none;
    display: block;    
}
.modalBody {
    padding: 20px 15px 20px 15px;
}

.buttonsWrapper {
    text-align: right;
    margin-right: 0;    
}
.defaultButton {
    color: inherit;
    transition: all 0.5s ease;
    background-color: transparent;
    box-shadow: none;        
    border: 1px solid #ddd;
}
.span {
    font-size: 15px;
    display: block;    
}