.columnSpace {

    padding: 0;
}
.h4 {
    color: inherit;
    text-transform: none;
    font-size: 15px;
}
.h4 span {
    font-weight: 700
}
.default {
    color: inherit;
    background: inherit;
    border: inherit;
}