.titleWrapper {
    margin-bottom: .5rem;

    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}
.infoIcon {
    margin-left: 10px;
}
.info {
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1;
    display: none;
}
.info:hover {
    display: block;
}
.wrapper {
    position: relative;
    width: 100%;
}
.infoIcon:hover + .info {
    display: block;
}
.title {
    color: inherit;
    width: max-content;
    margin-bottom: 0;
}